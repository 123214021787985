import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ProductionCard, { ProductionCardType } from './production-card';
import { Draggable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { CircularLoading } from '../../shared/circular-loading';
import BucketSearch from './bucket-search';
import { useBucketFiltering } from './use-bucket-filtering';
import { usePermissions } from '../../user/selectors/use-permissions';
import { PermissionType } from 'harmony-constants';

const StyledPaper = styled(Paper)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    margin: '1rem',
    backgroundColor: theme.palette.grey['200'],
    boxShadow: theme.shadows[3],
    height: '45vh',
}));

interface CardsProps {
    isDraggingOver: boolean;
}

const Cards = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDraggingOver',
})<CardsProps>(({ isDraggingOver, theme }) => ({
    flex: 1,
    transition: 'background-color 0.2s ease',
    overflowY: 'scroll',
    padding: '0.5rem 1rem',
    '&::-webkit-scrollbar': {
        width: '0.4rem',
        backgroundColor: theme.palette.grey['200']
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey['800']
    },
    ...(isDraggingOver && {
        backgroundColor: theme.palette.grey['500']
    })
}));

interface ProductionBucketProps extends DroppableProvided, DroppableStateSnapshot {
    isLoading?: boolean,
    cards: ProductionCardType[],
    innerRef: (element: HTMLElement | null) => any,
    children?: React.ReactNode;
    disableReordering?: boolean
    disableSearch?: boolean
}

const ProductionBucket: React.FC<ProductionBucketProps> = (props) => {
    const {
        isLoading = false,
        cards,
        disableReordering,
        innerRef,
        placeholder,
        isDraggingOver,
        children,
        disableSearch = false,
        ...rest
    } = props;

    const canCreateOrder = usePermissions(PermissionType.CreateOrder);
    const [search, setSearch] = React.useState('');
    const filtered = useBucketFiltering(cards, search);

    return (
        <StyledPaper {...rest.droppableProps}>
            {isLoading && <CircularLoading/>}
            {!disableSearch &&
            <BucketSearch searchText={search} setSearchText={setSearch} numOfRecords={cards.length}/>}
            <Cards ref={innerRef} isDraggingOver={isDraggingOver} {...rest.droppableProps}>
                {filtered.map((card, i) =>
                    <Draggable key={card.id} draggableId={`${card.id}`} index={i} isDragDisabled={!canCreateOrder}>
                        {(provided, snapshot) => <ProductionCard disableReordering={disableReordering}
                                                                 card={card} {...provided} {...snapshot}/>}
                    </Draggable>)
                }
                {placeholder}
            </Cards>
            {children}
        </StyledPaper>
    );
};

export default ProductionBucket;
