import React from 'react';
import { HereMap } from './here-maps/here-map';
import '../scss/here-map.scss'
import '../scss/live-map.scss';
import { Button, TextField } from '@mui/material';
import { HereMapRoute } from './here-maps/here-map-route';

export const MapDemo: React.FC = () => {
    const [multiline, setMultiline] = React.useState('');
    const [parsedPoints, setParsedPoints] = React.useState([]);

    const parseMultiline = () => {
        const points = multiline.split('\n');
        const parsedPoints123 = points.map(point => {
            const trimStart = point.substring(7);
            const trimEnd = trimStart.substring(0, trimStart.length - 1);
            const split = trimEnd.split(' ');
            return [split[1], split[0], null];
        });
        // @ts-ignore
        setParsedPoints(parsedPoints123);
    }

    return (
        <div className='live-map-content'>
            <div style={{ width: '20vw', padding: '10px' }}>
            <TextField
                id='outlined-multiline-flexible'
                label='Multiline'
                multiline
                maxRows={4}
                onChange={(event) => {
                    setMultiline(event.target.value);
                }}
            />
            <Button variant='contained' onClick={parseMultiline}>Button</Button>
            </div>
            <div style={{ width: '100vw', height: '100vh' }}>
                <HereMap centerPosition={{ lat: 42.3025432, lng: -93.5598171 }}>
                    <div></div>
                    {parsedPoints.length ?
                        <HereMapRoute calculateRouteFromWaypoints={false}
                                waypoints={parsedPoints} /> : null}
                </HereMap>
            </div>
        </div>
    );
};
