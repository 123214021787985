import React from 'react';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import Close from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';

const StyledAppBar = styled(AppBar)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    boxShadow: 'none',
    padding: '1rem 0 1rem 1rem',
});

const TabWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexGrow: 1,
    maxWidth: '55rem',
    justifyContent: 'space-between',
})

export const BULK_CREATION_TABS = () => [
    { title: L.bulkCreateTab1() },
    { title: L.bulkCreateTab2() },
    { title: L.bulkCreateTab3() },
];

export const BulkOrderTabs = (props) => {
    const { bulkTabIndex, setBulkTabIndex, closeBulkCreation } = props;

    const handleTabChange = (event, newValue) => {
        if (newValue >= bulkTabIndex) {
            return;
        }
        setBulkTabIndex(newValue);
    };

    return (
        <StyledAppBar>
            <TabWrapper>
                <Typography variant='h6'>{L.creatingLoads()}</Typography>
                <Tabs value={bulkTabIndex} onChange={handleTabChange} orientation='horizontal' textColor='inherit'>
                    {BULK_CREATION_TABS().map((tab, i) => <Tab key={i} label={tab.title}
                        disabled={i > bulkTabIndex}/>)}
                </Tabs>
            </TabWrapper>
            <Button style={{ color: 'white' }} onClick={() => closeBulkCreation({ completed: false })}>
                <Close/>
            </Button>
        </StyledAppBar>
    );
};

BulkOrderTabs.propTypes = {
    bulkTabIndex: PropTypes.number,
    closeBulkCreation: PropTypes.func,
    setBulkTabIndex: PropTypes.func,
};
