import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { L } from 'harmony-language';

export const PremiseIdInput = ({ orgLocation, onOrgLocationChange }) => {
    const disabled = !orgLocation.location?.address;

    return (
        <TextField
            variant='standard'
            label={L.premiseId()}
            id='premise-id'
            style={{ width: '100%' }}
            disabled={disabled}
            value={orgLocation.location?.address?.premiseId || ''}
            onChange={(e) => {
                onOrgLocationChange({ ...orgLocation, location: { ...orgLocation.location, address: { ...orgLocation.location.address, premiseId: e.target.value } } });
            }}
            inputProps={{ maxLength: 7 }} />
    );
};

PremiseIdInput.propTypes = {
    orgLocation: PropTypes.object,
    onOrgLocationChange: PropTypes.func
};
