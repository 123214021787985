// export const ACCESS_APPLICATION = 'accessApplication';
export const DOWNTIME = 'downtime';
export const ACCESS_SERVER_API = 'serverApi';
export const CASCADING_STOP_SCHEDULING = 'cascadingStopScheduling';
export const MERGE_DRAFTS = 'mergeDrafts';
// export const DELIVERY_TRACKING = 'deliveryTracking';
// export const CHECKLISTS = 'checklists';
// export const SITE_ISSUES = 'siteIssues';
// export const PRODUCTION_PLANNING = 'productionPlanning';
export const DELIVERY_TICKETS = 'deliveryTickets';
