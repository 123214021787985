import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { StopType } from '../../../types';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { disableReorderingOnDrag } from '../../shared/component-utils';
import { useLocationInfo } from '../../shared/hooks/use-location-info';
import { L } from 'harmony-language';
import { useConvertedUnitWeight } from '../../user/selectors/use-converted-unit';
import Skeleton from "@mui/material/Skeleton";
import { SalmonellaMeter } from '../../shared/salmonella-meter';
import { getTransCargoTypeLabel } from '../../../constants/constants';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Delete from '@mui/icons-material/Delete';
import { ConfirmationDialog } from '../../shared/confirmation-dialog';
import { useBool } from '../../shared/hooks/use-bool';
import { useDeleteProductionCardMutation } from '../../../api/mutations/delete/use-delete-production-card-mutation';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';
import { useWeights } from '../../shared/hooks/use-weights';
import { usePermissions } from '../../user/selectors/use-permissions';
import { PermissionType } from 'harmony-constants';

const InnerCard = styled('div')({
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
});

const StyledPaper = styled(Paper)({
    display: 'flex',
    padding: '0.5rem 1rem',
    margin: '0.5rem 0',
});

const TextSection = styled('div')({
    flex: 1,
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
        background: theme.palette.primary.dark,
    }
}));

const StyledBadge = styled(Badge)({
    width: '100%',
    '& .MuiBadge-badge': {
        transform: 'scale(1) translate(70%, 70%)',
    }
});

export type ProductionCardType = {
    id: number,
    // organizationId: number,
    guid: string,
    targetDate: string,
    stops: {
        type: StopType,
        organizationLocationId: number,
        organizationSubLocationIds: number[] | null,
    }[],
    cargoTypeId: number,
    quantity: number | null,
    productionPlan?: string | null,
    lotId?: string | null,
    orderNumber?: string | null,
    metadata: {
        cargo: {
            averageWeight?: number | null,
            numberOfLoads?: number | null,
            salmonellaResult?: string,
            weight?: number,
        }
    }
}

interface ProductionCardProps extends DraggableProvided, DraggableStateSnapshot {
    card: ProductionCardType,
    disableReordering?: boolean
}

const LoadingCard: React.FC = () => {
    return (
        <InnerCard>
            <div style={{ flex: 1 }}>
                <Skeleton variant={'text'}/>
                <Skeleton variant={'text'}/>
            </div>
            <div style={{ flex: 1, margin: '0 2rem' }}>
                <Skeleton variant={'text'}/>
                <Skeleton variant={'text'}/>
            </div>
            <div style={{ flex: 1 }}>
                <Skeleton variant={'text'}/>
                <Skeleton variant={'text'}/>
            </div>
        </InnerCard>
    );
};

const ProductionCard: React.FC<ProductionCardProps> = (props) => {
    const { card, disableReordering, innerRef, draggableProps, dragHandleProps, isDragging, isDropAnimating } = props;

    const { stops } = card;
    const origin = stops.find(s => s.type === 'Origin');
    const {
        toSiteDisplayName: toOriginDisplay,
        isLoading: isLoadingOrig
    } = useLocationInfo(origin?.organizationLocationId as number, 'Origin');
    const destination = stops.find(s => s.type === 'Destination');
    const {
        toSiteDisplayName: toDestinationDisplay,
        isLoading: isLoadingDest
    } = useLocationInfo(destination?.organizationLocationId as number, 'Destination');
    const { result, unitTranslation } = useConvertedUnitWeight(card.metadata.cargo.averageWeight || undefined);
    const isLoading = isLoadingOrig || isLoadingDest;
    const { mutate: deleteCard } = useDeleteProductionCardMutation();
    const [isOpen, { on, off }] = useBool(false);
    const numberOfLoads = card.metadata.cargo.numberOfLoads;
    const { convertFromGrams, weightAbbreviation } = useWeights();
    const displayQuantityWeight = card.quantity ?? convertFromGrams(card.metadata.cargo.weight || 0) + ' ' + weightAbbreviation;
    const canDeleteProductionCard = usePermissions(PermissionType.DeleteProductionCards);

    return (
        <>
            <StyledPaper ref={innerRef} {...draggableProps} {...dragHandleProps}
                   style={disableReordering ? disableReorderingOnDrag(draggableProps.style, {
                       isDragging,
                       isDropAnimating
                   }) : draggableProps.style}
            >
                <StyledBadge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={canDeleteProductionCard &&
                        <StyledIconButton
                            size='small'
                            aria-label={L.delete()}
                            onClick={on}>
                            <Delete/>
                        </StyledIconButton>
                    }>
                    {isLoading ? <LoadingCard/> :
                        <InnerCard>
                            <TextSection>
                                <div>{toOriginDisplay(origin?.organizationSubLocationIds)}</div>
                                <div>{toDestinationDisplay(destination?.organizationSubLocationIds)}</div>
                            </TextSection>
                            <TextSection style={{ textAlign: 'center' }}>
                                <div>{displayQuantityWeight}&nbsp;·&nbsp;{card.productionPlan || getTransCargoTypeLabel(card.cargoTypeId)}{card.orderNumber ? ' · ' + card.orderNumber : ''}</div>
                                {numberOfLoads
                                    ? <div>{`${L.numberOfLoads()}: ${numberOfLoads}`}</div>
                                    : <div>{`${L.averageWeight()}: ${result} ${unitTranslation}`}</div>
                                }
                            </TextSection>
                            <TextSection style={{ textAlign: 'right' }}>
                                <div>{localDateTimeDisplay(card.targetDate)}</div>
                                <div>{card.lotId}</div>
                                <SalmonellaMeter severity={card.metadata.cargo.salmonellaResult}
                                                 style={{ justifyContent: 'flex-end' }}/>
                            </TextSection>
                        </InnerCard>
                    }
                </StyledBadge>
            </StyledPaper>
            <ConfirmationDialog
                open={isOpen}
                resultCallback={(confirmed) => {
                    if (confirmed) {
                        deleteCard(card);
                    }
                    off();
                }}
                title={L.productionPlanningCardDeleteTitle()}
                message={L.productionPlanningCardDeleteMessage()}
                cancelText={L.cancel()}
                confirmText={L.delete()}
            />
        </>
    );
};

export default ProductionCard;
