import { L } from 'harmony-language';
import React from 'react';
import { useParams } from 'react-router';
import { OrgQueryKeys } from '../../api/config';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { type Checklist, type EquipmentResourcePath, type TractorTrailerResource } from '../../types';
import { Content } from '../shared/containers/content';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { ResourceTable } from '../shared/resource-table/resource-table';
import { ChecklistEntry } from './checklist-entry';
import { useMaintenanceColumns } from './use-maintenance-columns';

export const EquipmentChecklistHistory: React.FC = () => {
    const { id, type } = useParams<{
        id?: string;
        type?: TractorTrailerResource;
    }>();

    if (!id || !type || !Number(id)) {
        return (
            <Content>
                <h1>{L.noEquipmentChecklistsFound()}</h1>
            </Content>
        );
    }
    const {
        data: resourceChecklistsHistory,
        isLoading: isResourceChecklistsHistoryLoading
    } = useOrganizationQuery<Checklist[]>(
        OrgQueryKeys.getResolveCombinedChecklists(
            type.toLowerCase() as EquipmentResourcePath<typeof type>,
            Number(id)
        )
    );

    const {
        columns,
        isLoading: isLoadingColumns,
        resource: resource,
        setResource: setResource
    } = useMaintenanceColumns({ isHistory: true });

    const existingChecklist = React.useMemo(
        () =>
            resource && resourceChecklistsHistory
                ? resourceChecklistsHistory?.find(
                      (x) => x.id === resource?.rowId
                  )
                : undefined,
        [resource, resourceChecklistsHistory]
    );

    return (
        <>
            <ModalDialog
                open={Boolean(resource)}
                onClose={() => setResource(null)}
                title={L.equipmentChecklist()}
            >
                {resource && existingChecklist && (
                    <ChecklistEntry
                        resourceType={resource.type}
                        resource={resource}
                        onSubmit={() => setResource(null)}
                        isHistoricChecklist={true}
                        existingChecklist={existingChecklist}
                    />
                )}
            </ModalDialog>
            <Content>
                <ResourceTable
                    id={'checklist'}
                    title={L.equipmentChecklistHistory()}
                    isLoading={
                        isResourceChecklistsHistoryLoading || isLoadingColumns
                    }
                    columns={columns}
                    data={resourceChecklistsHistory || []}
                    options={{ sortBy: [{ id: 'updatedAt', desc: true }] }}
                />
            </Content>
        </>
    );
};
