import { Customer, OrganizationLocation } from '../../../../types';
import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { L } from 'harmony-language';

type CustomerDropdownProps = {
    orgLocation: OrganizationLocation,
    customers: Customer[],
    onOrgLocationChange: (orgLocation: OrganizationLocation) => void;
}

export const CustomerDropdown: React.FC<CustomerDropdownProps> = (props) => {
    const { orgLocation, customers, onOrgLocationChange } = props;
    if (customers.length <= 0) {
        return null;
    }
    
    const sortedCustomers = customers.sort((a, b) => a.customerName.localeCompare(b.customerName))
    const modifiedCustomers = [{ id: -1, customerName: 'None' }, ...sortedCustomers];

    return (
        <TextField
            variant='standard'
            className='customer-dropdown'
            InputLabelProps={{ shrink: true }}
            label={L.customer()}
            id='customer'
            style={{ width: '100%' }}
            select={true}
            value={orgLocation.customerId || -1}
            onChange={(e) => {
                let value = parseInt(e.target.value) || null;

                if (value === -1) {
                    value = null;
                }
                onOrgLocationChange({ ...orgLocation, customerId: value });
            }}>
            {modifiedCustomers.map((customer) => (
                <MenuItem key={customer.id} value={customer.id}>
                    {customer.customerName}
                </MenuItem>
            ))}
        </TextField>
    );
};
