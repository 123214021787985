import React from 'react';
import { L } from 'harmony-language';
import {
    ASSIGN_DRIVERS,
    COMPLETE_ORDER_PERMISSION,
    CREATE_ORDER,
    DELETE_ORDER,
    EDIT_ORDER
} from '../../permissions/permissions';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentReturned from '@mui/icons-material/AssignmentReturned';
import Public from '@mui/icons-material/Public';
import Edit from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ContentHeader } from '../shared/containers/content-header';
import { Content } from '../shared/containers/content';
import { ORDER_STATUSES } from '../../constants/constants';
import { DrawerDialog } from '../shared/containers/drawer-dialog';
import { DriverAssignment } from '../drivers/driver-assignment';
import { CarrierAssignment } from '../carriers/carrier-assignment';
import { useBoolean } from '../shared/hooks/use-boolean';
import { downloadBillsOfLading, downloadLoadsCSV, downloadDeliveryTickets } from '../../utils/download-utils';
import { useLoadsColumns } from './use-loads-columns';
import { useCarrier, useFeatures, usePermissions } from '../user/selectors/use-permissions';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { EditCreateOrder } from './edit-create-order/edit-create-order';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { useLoads } from '../../api/queries/use-loads';
import { QueryKeys } from '../../api/config';
import { useLoadsTableOptions } from '../shared/load-table/utils/use-loads-table-options';
import { LoadTable } from '../shared/load-table/load-table';
import Typography from '@mui/material/Typography';
import Delete from '@mui/icons-material/Delete';
import { DeleteLoadsModal } from '../drafts/delete-loads-modal';
import { ManuallyCompleteModal } from './manually-complete/manually-complete-modal';
import { useCarriers } from '../../api/queries/use-carriers';
import { DeliveryLog } from './delivery-log/delivery-log';
import { useTravelTimesQuery } from '../travel-times/use-travel-times';
import { DELIVERY_TICKETS } from '../../permissions/features';
import { useWeights } from '../shared/hooks/use-weights';
import { useLoadsKey } from '../../api/config-hooks';

export const Loads = () => {
    const canCreateOrder = usePermissions(CREATE_ORDER);
    const { loads, isLoading, isFetching, refetch } = useLoads();
    useTravelTimesQuery(isFetching);
    const { carriers, organizationId } = useCarriers();
    const tableOptions = useLoadsTableOptions(QueryKeys.loads);
    const { weightSystem } = useWeights();
    const [dialogOpen, openDialog, closeDialog] = useBoolean(false);
    const [deliveryDialogOpen, deliveryOpenDialog, deliveryCloseDialog] = useBoolean(false);
    const [editableLoad, setEditableLoad] = React.useState(null);
    const [deletableLoads, setDeletableLoads] = React.useState([]);
    const [assignableLoads, setAssignableLoads] = React.useState([]);
    const [completableLoads, setCompletableLoads] = React.useState([]);
    const [carrierLoads, setCarrierLoads] = React.useState([]);
    const [unselect, setUnselectFn] = React.useState(null);
    const [deliveryLogId, setDeliveryLogId] = React.useState(null);
    const currentUserIsCarrierOrg = useCarrier();
    const [clearSelection, setClearSelection] = React.useState(false);
    const loadsKey = useLoadsKey()

    React.useEffect(() => {
        if (assignableLoads.length === 0 || carrierLoads.length === 0 || completableLoads.length === 0) {
            setClearSelection(false);
        }
    }, [assignableLoads, carrierLoads, completableLoads]);

    const { columns, stopColumns, isLoading: isLoadingColumns } = useLoadsColumns();
    const hasDeliveryTicketsFeature = useFeatures(DELIVERY_TICKETS);

    const actions = React.useMemo(
        () => [
            {
                id: 'delivery-log',
                label: L.deliveryLog,
                icon: <Public />,
                action: (rows) => {
                    setDeliveryLogId(rows[0].id);
                    deliveryOpenDialog();
                },
                condition: (rows) => rows.length === 1
            },
            {
                id: 'export',
                label: L.exportData,
                icon: <CloudDownloadIcon />,
                action: (rows) => downloadLoadsCSV(organizationId, weightSystem, rows.map(x => x.id)),
            },
            {
                id: 'bill-of-lading-pdf',
                label: L.billOfLading,
                icon: <PictureAsPdfIcon />,
                action: (rows) => {
                    downloadBillsOfLading(rows.map(x => x.id));
                },
            },
            ...(hasDeliveryTicketsFeature ? 
            [{
                id: 'delivery-ticket-pdf',
                label: L.deliveryTicket,
                icon: <PictureAsPdfIcon />,
                action: (rows) => {
                    downloadDeliveryTickets(rows.map(x => x.id));
                }
            }] : []),
            {
                id: 'manually-complete',
                label: L.manuallyCompleteLoad,
                icon: <AssignmentTurnedIn />,
                action: (rows) => {
                    setCompletableLoads(rows);
                },
                permissions: [COMPLETE_ORDER_PERMISSION],
                condition: (rows) => rows.every(x => x.status !== ORDER_STATUSES().Delivered.key),
                displayBool: currentUserIsCarrierOrg ? false : true, //if logged into carrier org, don't show this button.
            },
            {
                id: 'assign-carrier',
                label: L.assignCarrier,
                icon: <AssignmentReturned />,
                action: (rows) => {
                    setCarrierLoads(rows);
                },
                condition: (rows) => rows.every(x => x.status !== ORDER_STATUSES().Delivered.key),
                displayBool: !currentUserIsCarrierOrg && carriers?.length > 0 ? true : false,
            },
            {
                id: 'assign',
                label: L.assignDriver,
                icon: <AssignmentIndIcon />,
                action: (rows) => {
                    setAssignableLoads(rows);
                },
                condition: (rows) => {
                    const delivered = rows.every(x => x.status !== ORDER_STATUSES().Delivered.key);
                    const sameOrg = rows.every(x => x.transportingOrganizationId === organizationId);
                    return delivered && sameOrg;
                },
                permissions: [ASSIGN_DRIVERS]
            },
            {
                id: 'edit',
                label: L.editLoad,
                icon: <Edit />,
                action: (rows) => {
                    setEditableLoad(rows[0]);
                    openDialog();
                },
                permissions: [EDIT_ORDER],
                condition: (rows) => rows.length === 1 && rows[0].status !== ORDER_STATUSES().Delivered.key
            },
            {
                id: 'delete',
                label: () => L.delete(),
                icon: <Delete/>,
                permissions: [DELETE_ORDER],
                action: async (rows, unselectFn) => {
                    setDeletableLoads(rows);
                    setUnselectFn(() => unselectFn);  
                },
                disabledTooltipText: L.deleteLoadsDisabledTooltipText(),
                condition: (rows) => rows.every(x => x.status === ORDER_STATUSES().Open.key || x.status === ORDER_STATUSES().Assigned.key),
                displayBool: currentUserIsCarrierOrg ? false : true, //if logged into carrier org, don't show this button.
            },
        ], [openDialog, deliveryOpenDialog, hasDeliveryTicketsFeature]);

    return (
        <>
            <ContentHeader>
                <Typography variant='h5'>{L.loads()}</Typography>
                {canCreateOrder && !currentUserIsCarrierOrg &&
                    <Button variant='contained' color='primary' onClick={openDialog}><Add /> {L.createLoad()}</Button>}
            </ContentHeader>
            <ModalDialog title={L.loadDetails()} onClose={() => {closeDialog(); setEditableLoad(null)}}
                // don't allow this modal to be dragable as react-draggable and react-beautiful-dnd do not seam to work well together.
                // drag in the stops table becomes very inconsistant when modal is dragable
                draggable={false}
                open={dialogOpen}>
                    <EditCreateOrder existingLoad={editableLoad}
                        onSubmit={() => {closeDialog(); setEditableLoad(null)}}
                        organizationId={organizationId} />
            </ModalDialog>
            <DeleteLoadsModal records={deletableLoads} unselectFn={unselect} onResultCallback={() => setDeletableLoads([])} queryKey={loadsKey} />
            <ModalDialog title={L.manuallyCompleteConfirmationTitle(`${completableLoads.length > 1 ? L.loads() : L.load()}`)}
                         open={completableLoads.length > 0}
                         onClose={() => {setCompletableLoads([]); closeDialog();}}>
                <ManuallyCompleteModal completableLoads={completableLoads}
                    onSubmit={() => {
                        setClearSelection(true);
                        setCompletableLoads([]);
                        closeDialog();
                    }}
                />
            </ModalDialog>
            <ModalDialog title={L.deliveryLog()}
                open={deliveryDialogOpen}
                onClose={() => {deliveryCloseDialog(); setDeliveryLogId(null);}}>
                    <DeliveryLog load={loads ? loads.find(x => x.id === deliveryLogId) : null} />
            </ModalDialog>
            <DrawerDialog title={L.assignCarrier()}
                open={carrierLoads.length > 0}
                onClose={() => setCarrierLoads([])} anchor='right'>
                <CarrierAssignment loads={carrierLoads}
                    onAssign={() => {
                        setClearSelection(true);
                        setCarrierLoads([]);
                    }}
                />
            </DrawerDialog>
            <DrawerDialog title={L.assignDriver()}
                open={assignableLoads.length > 0}
                onClose={() => setAssignableLoads([])} anchor='right'>
                <DriverAssignment loads={assignableLoads}
                    onAssign={() => {
                        setClearSelection(true);
                        setAssignableLoads([]);
                    }}
                />
            </DrawerDialog>

            <Content>
                <LoadTable
                    id='loads'
                    isLoading={isLoading || isFetching || isLoadingColumns}
                    columns={columns}
                    stopColumns={stopColumns}
                    data={loads || []}
                    actions={actions}
                    options={tableOptions}
                    clearSelection={clearSelection}
                    refetch={refetch}
                />
            </Content>
        </>
    );
};

Loads.propTypes = {};
