import React, { useEffect } from 'react';
import { OrgQueryKeys } from '../../api/config';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { LaxStop, Load, Stop, StopType } from '../../types';
import {
    orderToDefaultCargoType,
    orderToDefaultCompartments,
    orderToMissingStopQuantity,
    orderToMissingStopType,
    orderToMissingStopWeight,
    orderToNextStopSequence
} from '../../utils/data-mapping-utils';
import { withThroughputs } from '../../utils/throughput-utils';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useAddDraftMutation } from '../../api/mutations/add/use-add-draft-mutation';
import { useEditDraftMutation } from '../../api/mutations/edit/use-edit-draft-mutation';
import { useEditLoadMutation } from '../../api/mutations/edit/use-edit-load-mutation';
import { useAddLoadMutation } from '../../api/mutations/add/use-add-load-mutation';
import { useMergeDraftsMutation } from '../../api/mutations/use-merge-drafts-mutation';
import { isValidDate } from '../../utils/date-time-utils';
import { deepEqual } from '../../utils/deep-equal';
import { STOP_TYPES } from '../../constants/constants';

export type LoadEditFunctions = {
    onChange: (load: Partial<Load>) => void;
    addStop: () => void;
    splitStop: (stop: Stop) => void;
    deleteStop: (stop: Stop) => void;
    dragAndDrop: (result: DropResult, provided: ResponderProvided) => void;
    save: (options?: { previousDrafts?: Load[] }) => Promise<void> | void;
    compartmentLinkingEnabled: boolean;
    setCompartmentLinkingEnabled: (enabled: boolean) => void;
    isSaving: boolean;
    isSuccess: boolean;
    isError: boolean;
    reverseDestinations: () => void;
}
type LoadContextType = {
    load: Load;
    editFunctions?: LoadEditFunctions;
    disableConflicts?: boolean;
    isDirty?: boolean;
    // currentUserIsCarrier?: boolean;
}

export const LoadContext = React.createContext<LoadContextType>({
    load: {
        id: 0,
        organizationId: 0,
        date: '',
        stops: [],
        status: 'Open',
    },
    // currentUserIsCarrier: false,
});

export const useLoadContext = () => React.useContext(LoadContext);

type LoadMutation = 'AddLoad' | 'EditLoad' | 'AddDraft' | 'EditDraft' | 'MergeDrafts';

export const LoadProvider: React.FC<{ load: Load, mutation?: LoadMutation, disableConflicts?: boolean, children: React.ReactNode }> = (props) => {
    const { mutation, disableConflicts = false } = props;
    const isEditable = Boolean(mutation);

    const { data: throughputs } = useOrganizationQuery(OrgQueryKeys.throughput);

    const [load, setLoad] = React.useState(props.load);
    const [isDirty, setIsDirty] = React.useState(false);
    const [compartmentLinkingEnabled, setCompartmentLinkingEnabled] = React.useState(true);

    React.useEffect(() => {
        setLoad(props.load);
    }, [props.load]);

    React.useEffect(() => {
        setIsDirty(!deepEqual(props.load, load));
    }, [props.load, load]);

    const handleChange = React.useCallback((value: Partial<Load>) => {

        setLoad((x) => {
            const val = {
                ...x,
                ...value
            }
            //always update to the earliest stop date
            val.date = val.stops.filter(x => isValidDate(x.arrivalTime)).sortBy(x => new Date(x.arrivalTime as Date))[0]?.arrivalTime as Date || val.date;
            val.status = val.transportedByUserId ? 'Assigned' : 'Open';
            return val;
        })

    }, [throughputs]);


    const stops = load.stops as LaxStop[];

    const handleAddStop = React.useCallback(() => {
        if (stops.length !== stops[stops.length - 1].sequence) {
            for (let i = 0; i < stops.length; i++) {
                stops[i].sequence = i + 1;
            }
        }
        const nextSequence = orderToNextStopSequence({ stops });
        const stopType = orderToMissingStopType({ stops }) as StopType;
        const cargoTypeId = orderToDefaultCargoType({ stops });
        const newStops = stops.concat({
            orderId: load.id,
            status: 'Not Started',
            sequence: nextSequence,
            cargoTypeId,
            type: stopType,
            arrivalTime: null,
            orderNumber: null,
            productionPlan: null,
            lotId: null,
            quantity: orderToMissingStopQuantity({ stops: load.stops }),
            compartments: orderToDefaultCompartments({ stops }),
            splitId: null,
            notes: null,
            customerOrderNumber: null,
            requiredBegin: null,
            requiredEnd: null,
            readyTime: null,
            domKey: Math.random(),
            weight: orderToMissingStopWeight({ stops: load.stops }),
            actualWeight: null,
            metadata: null,
            timeSeconds: null,
            distanceMeters: null,
        }).sortBy((s) => s.sequence);

        setLoad((x) => ({
            ...x,
            stops: newStops.map(withThroughputs(throughputs))
        }))
    }, [load.stops, throughputs]);

    const handleSplitStop = React.useCallback((stop: Stop)=> {
        const nextSequence = stop.sequence + 1;
        const newSequence = stops.map(function(sequenceStop) {
            if (sequenceStop.sequence >= nextSequence) {
                sequenceStop.sequence++;
            }
            return sequenceStop;
        });
        const stopType = stop.type as StopType;
        const cargoTypeId = orderToDefaultCargoType({ stops });
        const newStops = newSequence.concat({
            orderId: load.id,
            status: 'Not Started',
            sequence: nextSequence,
            cargoTypeId,
            type: stopType,
            arrivalTime: null,
            quantity: Math.abs(orderToMissingStopQuantity({ stops: load.stops })),
            orderNumber: stop.orderNumber,
            productionPlan: stop.productionPlan,
            lotId: stop.lotId,
            splitId: stop.id,
            notes: stop.notes,
            customerOrderNumber: null,
            requiredBegin: null,
            requiredEnd: null,
            readyTime: null,
            domKey: Math.random(),
            weight: Math.abs(orderToMissingStopWeight({ stops: load.stops })),
            actualWeight: null,
            metadata: null,
            timeSeconds: null,
            distanceMeters: null,
        }).sortBy((s) => s.sequence);

        setLoad((x) => ({
            ...x,
            stops: newStops.map(withThroughputs(throughputs))
        }))
    }, [load.stops, throughputs]);

    const handleDeleteStop = React.useCallback((stop: Stop) => {
        setLoad((x) => ({
            ...x,
            stops: x.stops.filter(fs => fs !== stop)
        }))
    }, []);

    const handleDragAndDrop = React.useCallback((result: DropResult) => {
        const reorderedStops = [...load.stops];
        if (!result.destination) {
            return reorderedStops;
        }

        const dragIndex = result.source.index;
        const hoverIndex = result.destination.index;
        const dragStop = load.stops[dragIndex];

        const updatedStops = reorderedStops.resortValue(dragIndex, hoverIndex, dragStop).map((stop, i) => ({
            ...stop, sequence: i + 1
        }));

        setLoad((x) => ({
            ...x,
            stops: updatedStops
        }))
        return reorderedStops;
    }, [load.stops]);

    const { mutate: addDraft, isLoading: isAddingDraft } = useAddDraftMutation();
    const { mutate: editDraft, isLoading: isEditingDraft, isSuccess: isEditDraftSuccess, isError: isEditDraftError, reset: resetEditDraft } = useEditDraftMutation();
    const { mutate: editLoad, isLoading: isEditingLoad } = useEditLoadMutation();
    const { mutate: addLoad, isLoading: isAddingLoad } = useAddLoadMutation();
    const { mutate: mergeDrafts, isLoading: isMergingDraft } = useMergeDraftsMutation();

    useEffect(() => {
        setTimeout(resetEditDraft, 3000);
    }, [isEditDraftSuccess, isEditDraftError]);

    const handleSave = React.useCallback((options: { previousDrafts?: Load[] } = {}) => {
        switch (mutation) {
            case 'AddLoad':
                addLoad({ load });
                break;
            case 'EditLoad':
                editLoad({ load });
                break;
            case 'AddDraft':
                addDraft({ load });
                break;
            case 'EditDraft':
                editDraft({ draft: load });
                break;
            case 'MergeDrafts':
                if (options.previousDrafts) {
                    mergeDrafts({ draft: load, previousDrafts: options.previousDrafts });
                }
                break;
            default:
                break;
        }
    }, [load, editDraft, editLoad, addLoad, mergeDrafts, mutation]);

    const handleReverseDestinations = React.useCallback(() => {
        const destinationKey = STOP_TYPES().Destination.key;
        const reorderedStops = [...load.stops];

        const isDestinationArray = reorderedStops.map((s) => {
            if (s.type !== destinationKey) {
                return false;
            } else {
                return true;
            }
        });

        const newStops: Stop[] = [];
        let takenCareOfIndex = 0;
        isDestinationArray.map((isDestination, i) => {
            if (!isDestination) {
                newStops.push(reorderedStops[i]);
            } else {
                if (i >= takenCareOfIndex) {
                    const nextNonDestinationIndex = isDestinationArray.indexOf(false, i);

                    // goto end
                    if (nextNonDestinationIndex === -1) {
                        const destChunk = reorderedStops.slice(i, reorderedStops.length);
                        const reversed = Array.from(destChunk).reverse();
                        newStops.push(...reversed);
                        takenCareOfIndex = reorderedStops.length;
                    } else {
                        const destChunk = reorderedStops.slice(i, nextNonDestinationIndex);
                        const reversed = Array.from(destChunk).reverse();
                        newStops.push(...reversed);
                        takenCareOfIndex = nextNonDestinationIndex;
                    }
                }
            }
        });

        const sequencedNewStops = newStops.map((x, i) => ({ ...x, sequence: i + 1 }));

        setLoad((x) => ({
            ...x,
            stops: sequencedNewStops.map(withThroughputs(throughputs)),
        }));
    }, [load.stops]);

    const editFunctions = isEditable ? {
        onChange: handleChange,
        addStop: handleAddStop,
        splitStop: handleSplitStop,
        deleteStop: handleDeleteStop,
        dragAndDrop: handleDragAndDrop,
        save: handleSave,
        compartmentLinkingEnabled,
        setCompartmentLinkingEnabled,
        isSaving: isAddingLoad || isEditingLoad || isAddingDraft || isEditingDraft || isMergingDraft,
        isSuccess: isEditDraftSuccess,
        isError: isEditDraftError,
        reverseDestinations: handleReverseDestinations,
    } : undefined;

    return (
        // <LoadContext.Provider value={{ load: load, editFunctions, disableConflicts, isDirty, currentUserIsCarrier: currentUserIsCarrierOrg }}>
        (<LoadContext.Provider value={{ load: load, editFunctions, disableConflicts, isDirty }}>
            {props.children}
        </LoadContext.Provider>)
    );
}
