import Save from '@mui/icons-material/Save';
import { styled } from '@mui/material/styles';
import { L } from 'harmony-language';
import PropTypes from 'prop-types';
import React from 'react';
import { ORDER_STATUSES, STOP_TYPES, isCargoStop } from '../../../constants/constants';
import { isValidStopArrivalTime } from '../../../utils/data-mapping-utils';
import { HereMap } from '../../here-maps/here-map';
import { useWeights } from '../../shared/hooks/use-weights';
import { LoadProvider, useLoadContext } from '../../shared/load-context';
import {
    LoadDate,
    LoadDriver,
    LoadTractor,
    LoadTrailer,
} from '../../shared/load-table/load-table-cells';
import { StopsTable } from '../../shared/load-table/stops-table';
import {
    EditableStopLotId,
    EditableStopOrderNumber,
    StopAutoCalc,
    StopCargoType,
    StopLocationAuto,
    StopNotes,
    StopProductionPlan,
    StopQuantity,
    StopReadyTime,
    StopSplitIcon,
    StopSubLocations,
    StopTime,
    StopType,
    StopWeight
} from '../../shared/load-table/stops-table-cells';
import LoadingButton from '../../shared/loading-button';
import { createStop } from './create-stop';
import { HereMapOrderContent } from './here-map-order-content';

const MasterGrid = styled('form')({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gridColumnGap: '1rem',
    fontSize: '.875rem',
});

const LoadOptions = styled('div')({
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const LoadItem = styled('div')({
    paddingBottom: '.5rem',
});

const StopsTableRow = styled('div')({
    gridColumn: '1 / span 2',
});

const StyledHereMap = styled(HereMap)({
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    border: '1px solid #ccc',
    height: '100%',
    minHeight: '40vh',
    justifyContent: 'center',
    position: 'relative'
});

const Footer = styled('div')({
    gridColumn: '2',
    display: 'flex',
    justifyContent: 'flex-end'
});

const EditCreateOrderForm = (props) => {
    const { onSubmit } = props;
    const { load, editFunctions } = useLoadContext();
    const { convertFromGrams, weightPrecision } = useWeights();

    const areStopQuantitiesValid = React.useCallback(() => {
        return load.stops.sum((stop) => {
            const quantity = stop.quantity || 0;

            return stop.type === STOP_TYPES().Origin.key ? quantity : -1 * quantity;
        }) === 0;
    }, [load.stops]);

    const areStopWeightsValid = React.useCallback(() => {
        const sumTotal = load.stops.sum((stop) => {
            const weight = convertFromGrams(stop.weight || 0);

            return stop.type === STOP_TYPES().Origin.key ? weight : -1 * weight;
        });
        // Math.abs then toFixed will account for floating point precision overflow errors
        return Math.abs(sumTotal.toFixed(weightPrecision)) === 0;
    }, [load.stops]);

    const isStopArrivalTimeValid = React.useCallback((stop) => isValidStopArrivalTime(stop, load),
        [load.stops]);

    const atLeastOneHasValue = React.useCallback(() => {
        return load.stops.some((s) => s.quantity || s.weight);
    }, [load.stops]);

    const isAllOrNoneQuantityValid = React.useCallback(() => {
        const anyHasVal = load.stops.some((s) => {
            return s.quantity && s.quantity > 0 ? true : false;
        });

        return anyHasVal ? load.stops.filter(stop => isCargoStop(stop)).every(x => x.quantity) : true;
    }, [load.stops]);

    const isAllOrNoneWeightValid = React.useCallback(() => {
        const anyHasVal = load.stops.some((s) => {
            return s.weight && s.weight > 0 ? true : false;
        });

        return anyHasVal ? load.stops.filter(stop => isCargoStop(stop)).every(x => x.weight) : true;
    }, [load.stops]);

    const loadColumns = React.useMemo(() => ([
        { header: L.driver(), render: LoadDriver },
        { header: L.tractor(), render: LoadTractor },
        { header: L.trailer(), render: LoadTrailer },
    ]), []);

    const stopColumns = React.useMemo(() => ([
        { header: L.stopType(), render: StopType },
        { header: L.location(), render: StopLocationAuto, required: true },
        { header: L.subLocations(), render: StopSubLocations, required: false },
        { header: L.readyTime(), render: StopReadyTime, required: false },
        {
            header: L.time(), render: StopTime, required: true, validations: [{
                isValid: isStopArrivalTimeValid,
                message: L.loadInvalidStopTimeOrder()
            }]
        },
        { header: '', render: StopAutoCalc },
        {
            header: L.quantity(),
            render: StopQuantity,
            required: false,
            validations: [
                {
                    isValid: atLeastOneHasValue,
                    message: L.loadInvalidStopValue(),
                },
                {
                    isValid: areStopQuantitiesValid,
                    message: L.loadInvalidQuantity()
                },
                {
                    isValid: isAllOrNoneQuantityValid,
                    message: L.loadInvalidQuantityRequiredAllStops(),
                },
            ],
        },
        {
            header: L.weight(),
            render: StopWeight,
            required: false,
            validations: [
                {
                    isValid: areStopWeightsValid,
                    message: L.loadInvalidWeight(),
                },
                {
                    isValid: isAllOrNoneWeightValid,
                    message: L.loadInvalidWeightRequiredAllStops(),
                },
            ],
        },
        { header: L.cargoType(), render: StopCargoType, required: true },
        { header: L.split(), render: StopSplitIcon },
        { header: L.orderNumber(), render: EditableStopOrderNumber },
        { header: L.productionPlan(), render: StopProductionPlan },
        { header: `${L.pickUp()} #`, render: EditableStopLotId },
        { header: '', render: StopNotes }
    ]), [isStopArrivalTimeValid, areStopQuantitiesValid]);

    const mapRef = React.useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await editFunctions?.save();
        onSubmit();
    };

    return (
        <MasterGrid id={'edit-create-load'} onSubmit={handleSubmit}>
            <LoadOptions>
                <LoadItem>
                    <LoadDate />
                </LoadItem>
                {
                    loadColumns.map((c, i) => {
                        return (
                            <LoadItem key={i}>
                                <div>
                                    {c.header}
                                </div>
                                <c.render />
                            </LoadItem>
                        );
                    })
                }
            </LoadOptions>
            <div>
                <StyledHereMap ref={el => el && (mapRef.current = el)}>
                    <HereMapOrderContent mapRef={mapRef} />
                </StyledHereMap>
            </div>
            <StopsTableRow>
                <StopsTable columns={stopColumns} />
            </StopsTableRow>
            <Footer>
                <LoadingButton isLoading={editFunctions?.isSaving} icon={<Save />} type='submit' color={'primary'}
                    variant='contained'>{L.save()}</LoadingButton>
            </Footer>
        </MasterGrid>
    );
};

EditCreateOrderForm.propTypes = {
    onSubmit: PropTypes.func,
};

export const EditCreateOrder = (props) => {
    const { existingLoad, onSubmit, organizationId } = props;

    const [load] = React.useState(existingLoad || {
        organizationId: organizationId,
        transportingOrganizationId: organizationId,
        status: ORDER_STATUSES().Open.key,
        tractorId: null,
        trailerId: null,
        stops: [createStop(1, STOP_TYPES().Origin.key), createStop(2, STOP_TYPES().Destination.key)]
    });

    return (
        <LoadProvider load={load} mutation={existingLoad ? 'EditLoad' : 'AddLoad'}>
            <EditCreateOrderForm onSubmit={onSubmit} />
        </LoadProvider>
    );
};

EditCreateOrder.propTypes = {
    existingLoad: PropTypes.object,
    onSubmit: PropTypes.func,
    organizationId: PropTypes.number,
};
