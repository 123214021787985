import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

export const AddressItemInput = ({ addressItem, orgLocation, onOrgLocationChange, label, optional=false, disabled=false }) => {
    return (
        <TextField
            variant='standard'
            disabled={disabled}
            required={!optional}
            label={label}
            id={label}
            value={orgLocation.location?.address?.[addressItem] || ''}
            style={{ width: '100%' }}
            onChange={(e) => {
                onOrgLocationChange({ ...orgLocation, location: { ...orgLocation.location, address: { ...orgLocation.location?.address, [addressItem]: e.target.value } } });
            }}
            inputProps={{ maxLength: 200 }} />
    );
};

AddressItemInput.propTypes = {
    addressItem: PropTypes.string,
    orgLocation: PropTypes.object,
    onOrgLocationChange: PropTypes.func,
    label: PropTypes.string,
    optional: PropTypes.bool,
    disabled: PropTypes.bool
};

