import { L } from 'harmony-language';
import { LocationType, OrganizationLocation, Stop, StopType } from '../../../types';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useLocationTypes } from '../../../api/queries/use-location-types';
import { locationTypeToCargoTypeIdList } from '../../../utils/data-mapping-utils';
import React from 'react';
import { Typography } from '@mui/material';
import { toSiteDisplayName as utilSiteDisplayName } from '../../../utils/data-mapping';

type LocationInfo = {
    toSiteDisplayName: (sublocationIds?: number[] | null) => string;
    toSublocationDisplay: (uniqueStopSublocations: Stop[]) => string | null;
    toAddressDisplay: () => React.ReactNode;
    locationTypeId?: number;
    cargoTypeIds: number[];
    hasScale: boolean;
    isLoading: boolean;
}

export const useLocationInfo = (orgLocationId: number, stopType: StopType): LocationInfo => {
    const { data: locations, isLoading: isLoadingLocations } = useOrganizationQuery(OrgQueryKeys.locations);
    const { data: locationTypes, isLoading: isLoadingLocationTypes } = useLocationTypes();
    const orgLocation = (locations as OrganizationLocation[])?.find(x => x.id === orgLocationId);
    const locationType = (locationTypes as LocationType[])?.find(x => x.id === orgLocation?.organizationLocationTypeId);

    const toSiteDisplayName = React.useCallback((sublocationIds?: number[] | null) => {
        const sublocatioNames = orgLocation?.orgSubLocations?.filter(x => sublocationIds?.includes(x.id)).map(x => x.name).join(', ');
        return orgLocation ? utilSiteDisplayName(orgLocation.name, orgLocation?.description, sublocatioNames) : '';
    }, [orgLocation]);

    const toSublocationDisplay = React.useCallback((stopsWithSublocations: Stop[]) => {
        if (!stopsWithSublocations.length || !orgLocation) {
            return null;
        }

        const uniqueSublocationIds = [...new Set(stopsWithSublocations.flatMap(x => x.organizationSubLocationIds))];
        const sublocations = orgLocation.orgSubLocations?.filter(sub => uniqueSublocationIds.includes(sub.id)).sortBy(s => s.name) || [];
        return sublocations.map(s => s.name).join(' - ');
    }, [orgLocation]);

    const toAddressDisplay = React.useCallback(() => {
        const address = orgLocation?.location?.address;
        if (!address) {
            return <></>;
        }
        return <div style={{fontSize: 'smaller'}}>
            <div>{L.localeAddressLine1(address)}</div>
            {(L.localeAddressLine2(address).trim().length ? <div>{L.localeAddressLine2(address)}</div> : <></>)}
            <div>{L.localeAddressLine3(address)}</div>
        </div>
    }, [orgLocation])

    return {
        isLoading: isLoadingLocations || isLoadingLocationTypes,
        toSiteDisplayName,
        toSublocationDisplay,
        toAddressDisplay,
        cargoTypeIds: locationTypeToCargoTypeIdList(stopType)(locationType),
        locationTypeId: locationType?.id,
        hasScale: Boolean(orgLocation?.hasScale)
    };
};

// most of this is unused, I think only cargoTypeIds is ever used...
export const stopToLocationInfo = (locations: OrganizationLocation[], locationTypes: LocationType[]) => (stop: Stop) => {
    const orgLocation = (locations as OrganizationLocation[])?.find(x => x.id === stop.organizationLocationId);
    const locationType = (locationTypes as LocationType[])?.find(x => x.id === orgLocation?.organizationLocationTypeId);
    return {
        isLoading: false,
        toSublocationDisplay: (uniqueStopSublocations: Stop[]) => {
            const sublocationIds = uniqueStopSublocations.map(a => a.organizationSubLocationId);
            if (sublocationIds.length <= 0 || !orgLocation) {
                return null;
            }

            const sublocations = orgLocation.orgSubLocations?.filter(sub => sublocationIds.includes(sub.id)).sortBy(s => s.name) || [];
            return sublocations.map(s => s.name).join(' - ');
        },
        toAddressDisplay: () => {
            const address = orgLocation?.location?.address;
            if (!address) {
                return <></>;
            }
            return <Typography style={{fontSize:'smaller'}}>
                <div>{L.localeAddressLine1(address)}</div>
                {(L.localeAddressLine2(address).trim().length ? <div>{L.localeAddressLine2(address)}</div> : <></>)}
                <div>{L.localeAddressLine3(address)}</div>
            </Typography>
        },
        cargoTypeIds: locationTypeToCargoTypeIdList(stop.type)(locationType),
        locationTypeId: locationType?.id,
        hasScale: Boolean(orgLocation?.hasScale)
    };
};

export const useGetStopDisplayName = (): (stop: { organizationLocationId: number, organizationSubLocationId?: number | null }) => string => {
    const { data: locations } = useOrganizationQuery(OrgQueryKeys.locations);
    return React.useCallback((stop) => {
        const orgLocation = (locations as OrganizationLocation[])?.find(x => x.id === stop.organizationLocationId);
        const organizationSubLocation = orgLocation?.orgSubLocations?.find(x => x.id === stop.organizationSubLocationId);
        return [orgLocation?.name, organizationSubLocation?.name].filter(Boolean).join(' - ');
    }, [locations]);
};
