import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Close from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { TransitionProps } from '@mui/material/transitions';

const StyledAppBar = styled(AppBar)({
    position: 'sticky',
    width: '100%',
});

const HeaderBar = styled(AppBar)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    boxShadow: 'none',
    padding: '0.5rem 0 0.25rem 1rem',
});

const ModalContent = styled('div')({
    padding: '0.5rem 1rem',
    height: '100%',
    overflow: 'auto',
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

type DraggableComponentProps = PaperProps & { draggableHandleKey: string };
const DraggableComponent: React.FC<DraggableComponentProps> = (props) => {
    const { draggableHandleKey, ...rest } = props;
    const nodeRef = React.useRef(null);

    return (
        <Draggable
            nodeRef={nodeRef}
            handle={`#draggable-dialog-title-${draggableHandleKey}`}
            cancel={'[class*="modal-dialog-header-button"]'}
        >
            <Paper ref={nodeRef} {...rest} />
        </Draggable>
    );
};

const NormalComponent: React.FC<PaperProps> = (props) => {
    return <Paper {...props} />;
};

interface ModalDialogProps {
    className?: string;
    children?: any;
    fullScreen?: boolean;
    onClose: () => void;
    onExited?: (e: any) => void;
    open: boolean;
    title?: string;
    titleComponent?: any;
    fullWidth?: boolean;
    draggable?: boolean;
    draggableHandleKey?: string;
}

export const ModalDialog: React.FC<ModalDialogProps> = (props) => {
    const {
        fullScreen,
        open,
        onClose,
        onExited,
        title,
        titleComponent,
        className,
        children,
        fullWidth,
        draggable = true,
        draggableHandleKey = '',
    } = props;

    const handleClose = (_: object, reason: 'escapeKeyDown' | 'backdropClick') => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    return (
        <Dialog
            disablePortal={false}
            className={className}
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            maxWidth={false}
            fullWidth={fullWidth}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            // @ts-ignore
            // I have no idea how to get the custom draggableHandleKey props typed right
            // if you try and return a <DraggableComponent {...props} draggableHandleKey={draggableHandleKey} />
            // certain dialogs will crash (bulk draft create)
            PaperComponent={draggable ? DraggableComponent : NormalComponent}
            PaperProps={{
                draggableHandleKey: draggableHandleKey,
            }}
            TransitionProps={{
                onExited
            }}>
            <StyledAppBar id={`draggable-dialog-title-${draggableHandleKey}`}>
                {!titleComponent && (
                    <HeaderBar color={'primary'}>
                        <Typography
                            variant='h6'
                            style={{ maxWidth: 'fit-content' }}
                        >
                            {title}
                        </Typography>
                        <Button
                            className={'modal-dialog-header-button'}
                            type='button'
                            onClick={onClose}
                            style={{ color: 'white' }}
                        >
                            <Close />
                        </Button>
                    </HeaderBar>
                )}
                {titleComponent}
            </StyledAppBar>
            <ModalContent
                data-testid={'modal-dialog-content'}
            >
                {children}
            </ModalContent>
        </Dialog>
    );
};
