import { CircularProgress, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImageOutlined } from '@mui/icons-material';
import { ModalDialog } from '../containers/modal-dialog';
import { L } from 'harmony-language';
import Grid from '@mui/material/Grid';
import { ImageThumbnailExpand } from '../image-thumbnail-expand';
import React from 'react';
import { useBoolean } from '../hooks/use-boolean';
import { Image, Stop } from '../../../types';
import { DynamicQueryKeys } from '../../../api/config';
import axios from 'axios';

const IconDiv = styled('div')({
    display: 'inline-block',
    'vertical-align': 'middle',
    margin: 0,
    padding: 0,
});

interface StyledGridProps {
    isLoading: boolean;
}

const StyledGrid = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'isLoading',
})<StyledGridProps>(({ isLoading }) => ({
    flexGrow: 1,
    ...(isLoading && {
        width: '200px',
        height: '200px',
    })
}));

interface StopImagesModalProps {
    stop: Stop
}

export const StopImagesModal: React.FC<StopImagesModalProps> = (props) => {
    const { stop } = props;
    const [isOpen, open, close] = useBoolean(false);
    const [images, setImages] = React.useState<Image[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    if (!stop?.imageIds?.length) {
        return null;
    }

    const onClick = async () => {
        setIsLoading(true);
        const key = DynamicQueryKeys.stopImages(stop.id);
        open();
        const images = (await axios.get<Image[]>(`${API_BASE}/api${key}`)).data;
        setImages(images);
        setIsLoading(false);
    }

    return (
        <IconDiv>
            <IconButton sx={{ padding: 0 }} onClick={onClick}
                        size='small' color='primary'>
                <ImageOutlined />
            </IconButton>
            <ModalDialog title={`${L.stopPhotos()}`} open={isOpen} onClose={() => close()}>
                <StyledGrid container direction='row' justifyContent='center'
                      alignItems='center' spacing={1} isLoading={isLoading}>
                    {isLoading ?
                        <CircularProgress size={'4em'} /> :
                        images.map((image) => (
                            <Grid item key={image?.id}>
                                <ImageThumbnailExpand key={image?.id} image={image}
                                    label={L.stopPhoto()} />
                            </Grid>
                        ))
                    }
                </StyledGrid>
            </ModalDialog>
        </IconDiv>
    );
}
