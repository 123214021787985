import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { useCompanyId } from '../../../app/agistics-context';

export const useAddOrganizationResourceMutation = (queryKey: string, resourceName: string) => {
    const snackbar = useSnackbar();
    const companyId = useCompanyId();
    const key = OrgQueryKeys.resolve(companyId, queryKey);
    const queryClient = useQueryClient();

    return useMutation(async (resource) => {
        resource.organizationId = companyId;
        return (await axios.post(`${API_BASE}/api${key}`, resource)).data;
    }, {
        ...mutationOptions([key], queryClient),
        onSuccess: (data) => {
            const resources = queryClient.getQueryData<any>([key]);
            const newResources = [...resources, data].sortBy(u => u.id);

            queryClient.setQueryData([key], newResources);
            snackbar(L.createdMessage(resourceName));
        }
    });
};
