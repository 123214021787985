import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { L, UNITS, getCurrentUnitSystem } from 'harmony-language';
import { useWeights } from '../../shared/hooks/use-weights';
import { DriverEfficiencyReportColumns } from '../report-types';

export const useDriverEfficiencyColumns = (isAdmin: boolean) => {
    const columnHelper = createColumnHelper<DriverEfficiencyReportColumns>();
    const isMetric = getCurrentUnitSystem() === UNITS.Metric;
    const { weightAbbreviation } = useWeights();

    const columns = React.useMemo(() => {
        const distanceAbbreviation = isMetric ? L.unitsMetricDistanceAbbr() : L.unitsImperialDistanceAbbr();

        const adminSettings = () => {
            return [
                columnHelper.accessor('organization_id', {
                    header: () => L.organizationId(),
                    cell: info => info.getValue(),
                }),
                columnHelper.accessor('driver_id', {
                    header: () => L.driverId(),
                    cell: info => info.getValue(),
                }),
            ]
        }

        const adminColumns = isAdmin ? adminSettings() : [];
        const restColumns = [
            columnHelper.accessor('driver_name', {
                header: () => L.driverName(),
                cell: info => <div style={{ width: 'max-content'}}>{info.getValue()}</div>,
            }),
            columnHelper.accessor('driver_username', {
                header: () => L.driverUsername(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('driver_contract', {
                header: () => L.contract(),
            }),
            columnHelper.accessor('loads', {
                header: () => L.loads(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('distance', {
                header: () => `${L.distance()} (${distanceAbbreviation})`,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('distance_per_load', {
                header: () => `${L.distancePerLoad()} (${distanceAbbreviation})`,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('delivery_hours', {
                header: () => L.deliveryHours(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('delivery_hours_per_load', {
                header: () => L.deliveryHoursPerLoad(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('delivery_minutes_per_load_distance', {
                header: () => L.deliveryMinutesPerLoadDistance(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('driving_hours', {
                header: () => L.drivingHours(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('driving_hours_per_load', {
                header: () => L.drivingHoursPerLoad(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('origin_sites', {
                header: () => L.originStops(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('origin_sites_per_load', {
                header: () => L.originStopsPerLoad(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('origin_site_minutes', {
                header: () => L.originStopMinutes(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('origin_site_minutes_per_load', {
                header: () => L.originStopMinutesPerLoad(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('destination_sites', {
                header: () => L.destinationStops(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('destination_sites_per_load', {
                header: () => L.destinationStopsPerLoad(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('destination_site_minutes', {
                header: () => L.destinationStopMinutes(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('destination_site_minutes_per_load', {
                header: () => L.destinationStopMinutesPerLoad(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('splits_same_site', {
                header: () => L.splitsSameSite(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('splits_different_site', {
                header: () => L.splitsDifferentSite(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('weight', {
                header: () => `${L.weight()} (${weightAbbreviation})`,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('weight_per_load', {
                header: () => `${L.weightPerLoad()} (${weightAbbreviation})`,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('quantity', {
                header: () => L.quantity(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('quantity_per_load', {
                header: () => L.quantityPerLoad(),
                cell: info => info.getValue(),
            }),
        ];

        return [...adminColumns, ...restColumns];
    }, [weightAbbreviation, isMetric]);

    return columns;
}
