import { checkIfDateRangesOverlap, isBefore, local, utc } from '../../../utils/date-time-utils';
import { DAYSOFWEEK_BITMASK } from '../../../constants/constants';
import moment from 'moment-timezone';

function* generateRecurringDowntimes(downtime, searchDate) {
    // Bump out the search a week in both directions to catch wraparounds
    const searchDateBegin = moment(searchDate.begin).add(-1, 'weeks');
    const searchDateEnd = moment(searchDate.end).add(1, 'weeks');
    const timeDifferenceMS = moment(downtime.end).diff(moment(downtime.begin));
    const daysOfWeek = parseInt(downtime.daysOfWeek, 2);

    while (searchDateBegin < searchDateEnd) {
        //find out if the iterated day's day of week matches a day in the recurring schedule
        const dayBitValue = 2 ** (6 - (searchDateBegin.day() % 7));

        if ((daysOfWeek & dayBitValue) === dayBitValue) {
            //UTC conversion needed so hours match up with UTC passed in as searchDates and downtimes
            // console.log('moment    ', moment.utc(downtime.begin),  moment(downtime.begin).isDST());
            const instanceStart = `${searchDateBegin.format('YYYY-MM-DD')}T${moment.utc(downtime.begin).format('HH:mm:ss')}Z`;
            // console.log('new Date()', new Date(instanceStart), moment(new Date(instanceStart)).isDST());

            yield {
                begin: moment(instanceStart),
                end: moment(instanceStart).add(timeDifferenceMS, 'ms')
            };
        }
        searchDateBegin.add(1, 'days');
    }
}

export const filterForConflicts = (downtimes, dateRange) => {
    if (!dateRange) {
        return []; // return empty array
    }
    return downtimes.reduce((acc, downtime) => {
        if (!parseInt(downtime.daysOfWeek, 2)) {
            // filter for one-times
            if (checkIfDateRangesOverlap(dateRange, downtime)) {
                return [...acc, downtime]
            }
        } else {
            // filter for recurring
            // If downtime begin has not started yet then this downtime does not apply
            if (isBefore(dateRange.end, downtime.begin)) {
                return acc;
            }
            
            for (const recurringDowntime of generateRecurringDowntimes(downtime, dateRange)) {
                if (checkIfDateRangesOverlap(dateRange, recurringDowntime)) {
                    //return a hit on the downtime, but adjust the dates to match the recurrence that hit
                    return [...acc, {...downtime, begin: utc(recurringDowntime.begin), end: utc(recurringDowntime.end)}]
                }
            }
        }
        return acc;
    }, []);
};

export const mapDaysOfWeekToArray = (daysOfWeek) => {
    let mask = daysOfWeek;

    if (typeof daysOfWeek === 'string') {
        mask = parseInt(daysOfWeek, 2);
    }

    const weekDays = moment.weekdays();
    const weekDaysMin = moment.weekdaysMin();

    return Object.values(DAYSOFWEEK_BITMASK).map((value, i)=>{
        return {
            name: weekDays[i],
            abbr: weekDaysMin[i],
            value: value,
            selected: (mask & value) !== 0
        };
    });
};

export const calculateDayOfWeekOffset = (dateTime) => {
    if (!dateTime) {
        return 0;
    }

    const utcDay = moment(dateTime).utc().day();
    const localDay = local(dateTime).day();

    const diff = utcDay - localDay;

    return Math.abs(diff) === 6 ? -Math.sign(diff) : diff;
};


export const shiftMaskWithOffset = (mask, offset) => {
    let newMask = mask;

    if(offset > 0){
        const saturdayMask = (newMask & 1) * 64;

        newMask = (newMask >> 1) | saturdayMask;
    } else if(offset < 0){
        const sundayMask = (newMask & 64) / 64;

        newMask = newMask << 1 | sundayMask;
        newMask &= ~(1 << 7); // remove left most bit
    }

    return newMask;
};

export const shiftIndexWithOffset = (index, offset) => {
    // Shift the index by the offset and respect wrap around
    return (index + offset + 7) % 7;
};
