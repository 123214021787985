import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { L } from 'harmony-language';
import { mapDaysOfWeekToArray, shiftIndexWithOffset, shiftMaskWithOffset } from './util/downtime-utils';

const StyledChip = styled(Chip)({
    margin: '0 0.05rem',
    borderRadius: '1rem',
    height: '2rem',
    width: '2rem',
    '& .MuiChip-label': {
        padding: 0,
    },
    // can't get this to work.
    // '& .MuiChip-sizeSmall': {
    //     margin: '0 0.05rem',
    //     borderRadius: '0.75rem',
    //     height: '1.5rem',
    //     width: '1.5',
    // }
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const SpanLabel = styled('span')(({ theme }) => ({
    fontSize: '0.5rem',
    color: theme.palette.grey,
    position: 'absolute',
    marginTop: '-0.8rem'
}));

export const DaysOfWeek = (props) => {
    const { daysOfWeekBitmask, daysOfWeekOffset, small, onChange, clickable } = props;

    const mask = parseInt(daysOfWeekBitmask, 2);
    const daysOfWeek = mapDaysOfWeekToArray(shiftMaskWithOffset(mask, -daysOfWeekOffset));

    const handleDayClick = (index) => {
        const i = shiftIndexWithOffset(index, daysOfWeekOffset);
        const newBitmask = daysOfWeek[i].value ^ mask;

        if (onChange) {
            onChange(newBitmask.toString(2).padStart(7, '0'));
        }
    };

    const renderChips = (size = 'medium') => {
        return daysOfWeek.map((day, i) => {
            return <StyledChip
                size={size}
                key={day.name}
                label={day.abbr}
                title={day.name}
                style={{ opacity: (clickable ? '1' : '0.9') }}
                disabled={!clickable}
                color={day.selected ? (clickable ? 'primary' : 'secondary') : 'defaultGrey'}
                clickable={clickable}
                onClick={() => handleDayClick(i)}/>;
        });
    };

    if(small){
        return (
            <Row style={{ padding: '0.25rem' }}>
                {renderChips('small')}
            </Row>
        );
    }

    return (
        <div>
            <SpanLabel>{(!mask || mask === 0) ? L.doesNotRepeat() : L.repeatsOn()}</SpanLabel>
            <Row>
                {renderChips('medium')}
            </Row>
        </div>
    );
};

DaysOfWeek.propTypes = {
    daysOfWeekOffset: PropTypes.number,
    daysOfWeekBitmask: PropTypes.string,
    small: PropTypes.bool,
    onChange: PropTypes.func,
    clickable: PropTypes.bool,
};
