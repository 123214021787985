import React, { FormEvent } from 'react';
import '../../../../scss/location.scss';
import { getCurrentUnitSystem, L, UNITS } from 'harmony-language';
import { EditCreateLocationForm } from './edit-create-location-form';
import { LocationMap } from './location-map';
import Button from '@mui/material/Button';
import { OrgQueryKeys } from '../../../../api/config';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { SITE_CATEGORIES, SiteManager } from '../../../../constants/constants';
import { SnackbarTypes, useSnackbar } from '../../../notifications/use-snackbar';
import Grid from '@mui/material/Grid';
import { filterByRole } from '../../../user/utils/user-mappings';
import { ResourceEditCreateContainer } from '../../../shared/containers/resource-edit-create-container';
import { useLocationTypes } from '../../../../api/queries/use-location-types';
import { getDistance } from '../../../../utils/geo-utils';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { feetToMeters } from '../../../../utils/unit-conversion';
import useOrgLocationImages from '../../../../api/queries/use-org-location-images';
import { useOrgLocationThroughputs } from '../../../../api/queries/use-org-location-throughputs';
import { Customer, GeoLocation, LoadingTeam, Organization, OrganizationLocation, User } from '../../../../types';

const MAX_MILES_BETWEEN_SITE_AND_GEOZONE = 100;
const MAX_KILOM_BETWEEN_SITE_AND_GEOZONE = 160.9;

export const getMaxDistanceByUnit = () => {
    return getCurrentUnitSystem() === UNITS.Metric ? MAX_KILOM_BETWEEN_SITE_AND_GEOZONE : MAX_MILES_BETWEEN_SITE_AND_GEOZONE;
};

interface EditCreateLocationProps {
    existingLocation: OrganizationLocation | null;
    addLocation: UseMutateAsyncFunction<OrganizationLocation, any, OrganizationLocation, unknown>;
    editLocation: UseMutateAsyncFunction<OrganizationLocation, any, OrganizationLocation, unknown>;
    closeModal: () => void;
}

export const EditCreateLocation: React.FC<EditCreateLocationProps> = (props) => {
    const { existingLocation, addLocation, editLocation, closeModal } = props;
    const [geofence, setGeofence] = React.useState<GeoLocation>({});
    const [location, setLocation] = React.useState<OrganizationLocation>({ enabled: true });
    const [distanceBetween, setDistanceBetween] = React.useState(0);

    const { images = [], onLocationSubmit, onDroppedFiles, removeImage, isLoading: isImagesLoading } = useOrgLocationImages({ organizationLocation: existingLocation })
    const { throughputs = [], onThroughputSubmit, setThroughputs} = useOrgLocationThroughputs(existingLocation)

    const snackbar = useSnackbar();

    const { isLoading: isLoadingLocationTypes } = useLocationTypes();
    const { data: loadingTeams = [], isLoading: isLoadingLoadingTeams } = useOrganizationQuery<LoadingTeam[]>(OrgQueryKeys.loadingTeams);
    const { data: customers = [], isLoading: isLoadingCustomers } = useOrganizationQuery<Customer[]>(OrgQueryKeys.customers);
    const { data: users = [], isLoading: isLoadingUsers } = useOrganizationQuery<User[]>(OrgQueryKeys.users);
    const { data: organization, isLoading: isLoadingOrg } = useOrganizationQuery<Organization>('');
    const siteManagers = React.useMemo(() => {
        const filtered = filterByRole(users, SiteManager) || [];
        const sorted = filtered.sort((a, b) => a.name.localeCompare(b.name));
        return sorted;
    }, [users]);
    const isLoading = isLoadingLocationTypes || isLoadingLoadingTeams || isLoadingUsers || isLoadingCustomers || isImagesLoading;

    React.useEffect(() => {
        if (existingLocation) {
            setLocation(existingLocation);
            if (existingLocation.customGeofence) {
                setGeofence(existingLocation.customGeofence);
                setDistanceBetween(getDistance(existingLocation.location.geoLocation, { latitude: existingLocation.customGeofence.latitude, longitude: existingLocation.customGeofence.longitude } as GeoLocation));
            }
        }
    }, []);

    const unitsKey = getCurrentUnitSystem() === UNITS.Metric ? 'unitsMetricDistance' : 'unitsImperialDistance';
    const unitsLabel = L[unitsKey]();

    const distanceTooFar = () => {
        return distanceBetween > getMaxDistanceByUnit();
    };

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!geofence?.bbox) {
            snackbar(L.warningCreateGeozone(), SnackbarTypes.warning);
            return;
        }
        if (distanceTooFar()) {
            snackbar(L.warningGeoDistance(`${getMaxDistanceByUnit()}`, unitsLabel), SnackbarTypes.warning);
            return;
        }
        location.customGeofence = geofence as GeoLocation;
        location.customGeofence.radius = feetToMeters(location.customGeofence.radius);

        if (location.orgLocationType.category !== SITE_CATEGORIES.SITE) {
            location.loadingTeamId = null;
            location.users = [];
            location.location.address.premiseId = null;
        }

        let result;
        if (location.id) {
            result = await editLocation(location as OrganizationLocation);
        } else {
            result = await addLocation(location as OrganizationLocation);
        }
        await onLocationSubmit(result);
        await onThroughputSubmit(location.id || result?.id);
        closeModal();
    };

    return (
        <ResourceEditCreateContainer>
            <form id='edit-create-location-form' name='edit-create-location-form' onSubmit={submitForm}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3} lg={4}>
                        <EditCreateLocationForm
                            isLoading={isLoading}
                            orgLocation={location}
                            loadingTeams={loadingTeams}
                            customers={customers}
                            isExistingLocation={Boolean(existingLocation)}
                            onOrgLocationChange={setLocation}
                            siteManagers={siteManagers}
                            images={images}
                            throughputs={throughputs}
                            setThroughputs={setThroughputs}
                            onDelete={removeImage}
                            onDroppedFiles={onDroppedFiles}
                        />
                    </Grid>
                    <Grid item xs={12} md={9} lg={8}>
                        <LocationMap orgLocation={location} geofence={geofence}
                            changeGeofence={setGeofence} setDistanceBetween={setDistanceBetween}
                            onOrgLocationChange={setLocation} organization={organization} isLoading={isLoadingOrg}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type='submit'
                            title='Save'
                            disabled={!location.location || !location.location.address}
                            variant='contained'
                            color='primary'>{existingLocation ? L.updateLocation() : L.createLocation()}</Button>
                        <span className='location-edit-distance-note'>{L.geoDistance()}
                            <span
                                className={distanceTooFar() ? 'value invalid' : 'value valid'}> {distanceBetween.toPrecision(3)}</span> {unitsLabel}
                        </span>
                    </Grid>
                </Grid>
            </form>
        </ResourceEditCreateContainer>
    );
};
