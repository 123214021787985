import PropTypes from 'prop-types';
import { localDateTimeDisplay } from '../../utils/date-time-utils';
import { DaysOfWeek } from './downtime-days-of-week';
import { IconButton, ListItem, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { L } from 'harmony-language';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React from 'react';
import { calculateDayOfWeekOffset } from './util/downtime-utils';

const DowntimeRow = styled(ListItem)({
    justifyContent: 'space-between',
    padding: '1.4rem 0rem 0.6rem',
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderColor: '#e7e7e7'
});
const DowntimeDateRange = styled('span')({
    marginLeft: '1rem',
    marginRight: '1rem',
});
const DowntimeDetails = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
});

export const ResourceDowntimeRow = (props) => {
    const { downtime, onEdit, onDelete } = props;

    return (
        <DowntimeRow>
            <span>{downtime.description}</span>
            <DowntimeDetails>
                <DowntimeDateRange>
                    {`${localDateTimeDisplay(downtime.begin)} \u00a0- \u00a0${localDateTimeDisplay(downtime.end)}`}
                </DowntimeDateRange>
                <DaysOfWeek
                    daysOfWeekOffset={calculateDayOfWeekOffset(downtime?.begin)}
                    daysOfWeekBitmask={downtime.daysOfWeek}/>
                <Tooltip title={L.edit()}>
                    <IconButton
                        size='small'
                        color='primary'
                        disabled={false}
                        onClick={onEdit}
                    >
                        <EditOutlinedIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip ml={6} title={L.delete()}>
                    <IconButton
                        size='small'
                        color='primary'
                        disabled={false}
                        onClick={onDelete}
                    >
                        <DeleteOutlinedIcon/>
                    </IconButton>
                </Tooltip>
            </DowntimeDetails>
        </DowntimeRow>
    );
};

ResourceDowntimeRow.propTypes = {
    downtime: PropTypes.object.isRequired,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};
