import { L } from 'harmony-language';
import React from 'react';
import { DeviceLocation, Driver, Tractor, Trailer } from '../../../types';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';
import { useConvertedUnitSpeed } from '../../user/selectors/use-converted-unit';

export const LiveMapFreemiumTooltip: React.FC<{ deviceLocation: DeviceLocation, driver?: Driver, tractor?: Tractor, trailer?: Trailer }> = (props) => {
    const { deviceLocation, driver, tractor, trailer } = props;
    const driverName = driver ? `${driver.lastName}, ${driver.firstName}` : ''

    let speed = null;
    if (deviceLocation.speed) {
        speed = useConvertedUnitSpeed(deviceLocation.speed);
    }

    // items that end up in here maps tooltip refuse to use styles generated from styled() util from mui...
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            minWidth: '10rem',
            fontSize: '.8rem',
            padding: '.3rem .2rem 0 .5rem',
            color: '#666',
            justifyContent: 'space-between',
        }}>
            {driver && <div style={{ padding: '0.3rem 0' }}>{L.driver()}: <span style={{ color: '#000' }}>{driverName}</span></div>}
            {driver?.phone && <div style={{ padding: '0.3rem 0' }}>{L.phone()}: <span style={{ color: '#000' }}>{driver.phone}</span></div>}
            {tractor && <div style={{ padding: '0.3rem 0' }}>{L.tractor()}: <span style={{ color: '#000' }}>{tractor.userDisplayName}</span></div>}
            {trailer && <div style={{ padding: '0.3rem 0' }}>{L.trailer()}: <span style={{ color: '#000' }}>{trailer.userDisplayName}</span></div>}
            {deviceLocation.latitude && <div style={{ padding: '0.3rem 0' }}>{L.latitude()}: <span style={{ color: '#000' }}>{deviceLocation.latitude.toPrecision(7)}</span></div>}
            {deviceLocation.longitude && <div style={{ padding: '0.3rem 0' }}>{L.longitude()}: <span style={{ color: '#000' }}>{deviceLocation.longitude.toPrecision(7)}</span></div>}
            {speed && <div style={{ padding: '0.3rem 0' }}>{L.speed()}: <span style={{ color: '#000' }}>{speed.result}</span> {speed?.unitTranslation}</div>}
            <div style={{ paddingTop: '.2rem', fontSize: '.7rem' }}>{localDateTimeDisplay(deviceLocation.timestamp)}</div>
        </div>
    );
};
