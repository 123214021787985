import axios, { AxiosResponse } from 'axios';
import { QueryClient, QueryFunctionContext, QueryKey } from '@tanstack/react-query';
import { utc } from '../utils/date-time-utils';
import { EquipmentResourcePath, TractorTrailerResource } from '../types';
import { TimeWindow, TimeWindowWithPreset } from '../types';

export const fetchFunction = async (baseUrl: string, params = {}) => {
    const cancelTokenSource = axios.CancelToken.source();
    const axiosPromise = axios.get(
        `${API_BASE}/api${urlBuilder(baseUrl, params)}`,
        { cancelToken: cancelTokenSource.token }
    );

    // @ts-ignore ??
    axiosPromise.cancel = () => {
        cancelTokenSource.cancel('This query was cancelled by react-query');
    };
    return axiosPromise;
};

export const typedFetchFunction = async <T>(baseUrl: string, params = {}) => {
    const cancelTokenSource = axios.CancelToken.source();
    const axiosPromise: Promise<AxiosResponse<T, any>> = axios.get(
        `${API_BASE}/api${urlBuilder(baseUrl, params)}`,
        { cancelToken: cancelTokenSource.token }
    );

    // axiosPromise.cancel = () => {
    //     cancelTokenSource.cancel('This query was cancelled by react-query');
    // };
    return axiosPromise;
}

export const defaultQueryOptions = {
    queries: {
        queryFn: async (queryFunctionContext: QueryFunctionContext, options?: any) => {
            const key = queryFunctionContext.queryKey[0] as string;
            return (await fetchFunction(key, options?.params)).data;
        },
        staleTime: 0,
        cacheTime: 5 * 60 * 1000
    }
};

export const fiveMinQueryConfig = {
    queries: {
        queryFn: async (key: string, options?: any) => {
            return (await fetchFunction(key, options?.params)).data;
        },
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000
    }
};

export const mutationOptions = (key: QueryKey, queryClient: QueryClient) => ({
    // If the mutation fails, use the rollback function if available
    onError: (err: any, options: any, rollback: any) => rollback && rollback(),
    onSettled: () => {
        // this used to have an option of refetchInactive: true, but with react-query@4 this changed
        // now we need to specifiy a refetchType, which is hard to do with this abstracted options function
        // ie: if we set to refetchType: 'inactive' it will only refetch the query if it's inactive
        //     the default is refetchType: 'active', which 'should' always be true here, since we are
        //     calling this from mutations that are changing a specfic key
        queryClient.invalidateQueries(key);
        // queryClient.invalidateQueries(key, { refetchType: 'all' });
    }
});

// The method returned here queries API with key
// but also provides start and end times and then appends result to cache rather than overwriting
export const fetchWithTimeWindow = (timeWindow: TimeWindowWithPreset, extraParams = {}) => async (key: string) => {
    return fetchFunction(key, {
        startDate: utc(timeWindow.from),
        endDate: utc(timeWindow.to),
        ...extraParams
    }).then(({ data }) => ({
        data: data,
        timeWindow: timeWindow
    }));
};

interface ConfigExtraParams { 
    includeDrafts?: boolean;
}

export const typedFetchWithTimeWindow = <T>(timeWindow: TimeWindow, extraParams: ConfigExtraParams = {}) => async (queryFunctionContext: QueryFunctionContext) => {
    const key = queryFunctionContext.queryKey[0] as string;

    const response = await typedFetchFunction<T>(key, {
        startDate: utc(timeWindow.from),
        endDate: utc(timeWindow.to),
        ...extraParams
    });
    return response.data;
}

export const urlBuilder = (base: string, params: Record<string, any>) => {
    const p = new URLSearchParams();
    Object.entries(params).forEach(([k, v]) => {
        p.append(k, v);
    });
    let url = base;
    if (p.toString()) {
        url += `?${p.toString()}`;
    }
    return url;
};

// These keys do not expect any prefixes
export const QueryKeys = {
    self: '/self',
    loads: '/self/orders',
    drafts: '/self/drafts',
    completeLoads: '/orders/complete',
    avoidanceZones: '/self/avoidance-zones',
    cargoTypes: '/cargo-types',
    locationTypes: '/location-types',
    companies: '/organizations',
    features: '/features',
    roles: '/roles',
    permissions: '/permissions',
    productionCards: '/production-cards',
    productionCardGroups: '/production-cards/groups',
    siteIssueTypes: '/site-issue-types'
};

// These keys expect '/organizations/${id}' to be the prefix
export const OrgQueryKeys = {
    tractors: '/tractors',
    trailers: '/trailers',
    loadingTeams: '/loading-teams',
    locations: '/locations',
    customers: '/customers',
    locationTravelTimes: '/locations/travel-times',
    drivers: '/drivers',
    users: '/users',
    usersCarriers: '/users-carriers',
    serviceUsers: '/service-users',
    throughput: '/throughput',
    features: '/features',
    bookings: '/bookings',
    checklists: '/checklists',
    siteIssues: '/site-issues',
    carriers: '/carriers',
    getResolveCombinedChecklists: (
        resourceType: EquipmentResourcePath<TractorTrailerResource>,
        resourceId: number
    ) => `/checklists/${resourceType}/${resourceId}/resolveCombined`,
    resolve: (companyId: number, orgQueryKey: string) =>
        `/organizations/${companyId}${orgQueryKey}`,
    resolveDowntime: (
        companyId: number,
        orgQueryKey: string,
        resourceId: number,
        downtimeId?: number
    ) => {
        let path = `/organizations/${companyId}${orgQueryKey}/${resourceId}/downtime`;

        if (downtimeId) {
            path = path + '/' + downtimeId;
        }
        return path;
    },
    getLatestChecklist: (
        companyId: number,
        resourceType: EquipmentResourcePath<TractorTrailerResource>,
        resourceId: number
    ) => {
        return `/organizations/${companyId}${OrgQueryKeys.checklists}/${resourceType}/${resourceId}/latest`;
    },
    resolveChecklist: (
        companyId: number,
        resourceType: EquipmentResourcePath<TractorTrailerResource>,
        resourceId: number
    ) => {
        return `/organizations/${companyId}${OrgQueryKeys.checklists}/${resourceType}/${resourceId}/resolve`;
    },
    contacts: '/contacts',
    notificationConfigs: '/notification-configs',
    locationImages: (organizationId: number, organizationLocationId: number) => `/organizations/${organizationId}/locations/${organizationLocationId}/images`,
    locationThroughput: (organizationId: number, organizationLocationId: number) => `/organizations/${organizationId}/locations/${organizationLocationId}/throughput`,
    devices: '/devices',
};

// These keys are dynamically created through arrow function
export const DynamicQueryKeys = {
    loadsSummary: (startDate: string, endDate: string) => `/self/loads-summary?startDate=${startDate}&endDate=${endDate}`,
    loadsInTransit: (startDate: string, endDate: string) => `/self/orders?status=In Transit&startDate=${startDate}&endDate=${endDate}`,
    resourceDowntime: (companyId: number, startDate: string) => `/organizations/${companyId}/downtime?startDate=${startDate}`,
    projections: (startDate: string, endDate: string) => `/self/projections?startDate=${startDate}&endDate=${endDate}`,
    // draftsFiltered: (startDate, endDate) => `/self/drafts?startDate=${startDate}&endDate=${endDate}`,
    // orderLocations: (orderId) => `/orders/${orderId}/locations`,
    rolePermissions: (roleId: number) => `/roles/${roleId}/permissions`,
    // productionCardGroup: (guid) => `${QueryKeys.productionCardGroups}/${guid}`,
    resourceTripEvents: (type: string, id: number, startDate: string | null, endDate: string | null) => {
        let path = `/${type}/${id}/tripevents`;
        if (startDate && endDate) {
            path = path + `?startDate=${startDate}&endDate=${endDate}`;
        }
        return path;
    },
    resourceDeviceLocations: (type: string, id: number, startDate: string | null, endDate: string | null) => {
        let path = `/${type}/${id}/devicelocations`;
        if (startDate && endDate) {
            path = path + `?startDate=${startDate}&endDate=${endDate}`;
        }
        return path;
    },
    resourceTypeChecklistTemplates: (resourceType: EquipmentResourcePath<TractorTrailerResource>) => {
        return `/checklist-templates/${resourceType}`;
    },
    latestDeviceLocations: (staleTimeMinutes: number) =>
        `/devices/locations/latest?staleTimeMinutes=${staleTimeMinutes}`,
    stopImages: (stopId: number | undefined) => `/stops/${stopId}/images`,
    schedulingResourceInfo: (driverIds: number[], trailerIds: number[]) => `/scheduling/resource-info?driverIds=${driverIds}&trailerIds=${trailerIds}`,
};
