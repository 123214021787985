import Typography from '@mui/material/Typography';
import { L } from 'harmony-language';
import { DaysOfWeek } from '../../../downtime/downtime-days-of-week';
import { calculateDayOfWeekOffset } from '../../../downtime/util/downtime-utils';
import { localDateTimeDisplay } from '../../../../utils/date-time-utils';
import PropTypes from 'prop-types';
import React from 'react';

export const DowntimeConflictTooltip = (props) => {
    const { conflict } = props;

    return (
        <div>
            <Typography>{L.unavailableResource()}</Typography>
            <div>{conflict.description}</div>
            <div>
                {conflict.daysOfWeek && <DaysOfWeek small
                    daysOfWeekOffset={calculateDayOfWeekOffset(conflict.begin)}
                    daysOfWeekBitmask={conflict.daysOfWeek} />}
            </div>
            <div>{`${localDateTimeDisplay(conflict.begin)} \u00a0- \u00a0${localDateTimeDisplay(conflict.end)}`}</div>
        </div>
    );
};

DowntimeConflictTooltip.propTypes = {
    conflict: PropTypes.object
};
