import React from 'react';
import PropTypes from 'prop-types';
import { L } from 'harmony-language';
import { useDowntimeQuery } from '../../api/queries/use-downtime-query';
import { useDeleteOrganizationResourceDowntimeMutation } from '../../api/mutations/delete/use-delete-organization-resource-downtime-mutation';
import AddIcon from '@mui/icons-material/Add';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { ConfirmationDialog } from '../shared/confirmation-dialog';
import { useBoolean } from '../shared/hooks/use-boolean';
import { DowntimeEntry } from './resource-downtime-entry';
import { Button, List } from '@mui/material';
import { ResourceDowntimeRow } from './resource-downtime-row';
import Skeleton from "@mui/material/Skeleton";
import { ResourceEditCreateContainer } from '../shared/containers/resource-edit-create-container';
import { useCompanyId } from '../../app/agistics-context';

export const ResourceDowntime = (props) => {
    const { resourceType, resourceId } = props;
    const companyId = useCompanyId()
    const { data: resourceDowntime, isLoading } = useDowntimeQuery(resourceType, resourceId, companyId);
    const { mutate: deleteDowntime } = useDeleteOrganizationResourceDowntimeMutation(companyId, resourceType, resourceId);

    const [modalOpen, openModal, closeModal] = useBoolean(false);
    const [deleteConfirmOpen, openDeleteConfirm, closeDeleteConfirm] = useBoolean(false);
    const [deleteDowntimeItem, setDeleteDowntimeItem] = React.useState();
    const [downtimeItem, setDowntimeItem] = React.useState(null);

    const toSkeletonListItem = (x) => <Skeleton key={x} width='100%' height={32}/>;

    const toListItem = (downtime) => <ResourceDowntimeRow key={downtime.id}
        downtime={downtime}
        onEdit={() => {
            setDowntimeItem(downtime);
            openModal();
        }}
        onDelete={() => {
            setDeleteDowntimeItem(downtime);
            openDeleteConfirm();
        }}/>;

    const emptyDowntime = () => {
        return (
            <div style={{
                textAlign: 'center',
                height: '3rem',
                paddingTop: '2rem'
            }}>
                {L.noDowntime()}
            </div>
        );
    };

    return (
        <ResourceEditCreateContainer width={'50vw'} data-testid='resource-downtime'>
            <List role='list'>
                {isLoading ? Array.from(Array(5), (x, i) => i).map(toSkeletonListItem) :
                    resourceDowntime.length > 0 ?
                        resourceDowntime.map(toListItem) :
                        emptyDowntime()}
            </List>
            <div style={{
                marginTop: '1rem',
                textAlign: 'right'
            }}>
                <Button color='primary'
                    variant='contained'
                    onClick={() => {
                        setDowntimeItem(null);
                        openModal();
                    }}
                    startIcon={<AddIcon/>}>{L.add()}
                </Button>
            </div>
            <ModalDialog className='downtime-entry-modal' open={modalOpen} onClose={closeModal} draggableHandleKey='add-edit-downtime'
                title={downtimeItem ? `${L.edit()} ${L.downtime()}` : `${L.add()} ${L.downtime()}`}>
                <DowntimeEntry closeModal={closeModal} companyId={companyId} resourceType={resourceType}
                    resourceId={resourceId} existingDowntime={downtimeItem}/>
            </ModalDialog>
            <ConfirmationDialog
                open={deleteConfirmOpen}
                title={L.deleteConfirmationTitle()}
                message={L.deleteConfirmationMessage(deleteDowntimeItem?.description)}
                cancelText={L.cancel()}
                confirmText={L.delete()}
                resultCallback={(confirmed) => {
                    if (confirmed === true) {
                        deleteDowntime({ downtimeId: deleteDowntimeItem.id });
                    }
                    closeDeleteConfirm();
                }}
            />
        </ResourceEditCreateContainer>
    );
};


ResourceDowntime.propTypes = {
    resourceType: PropTypes.string.isRequired,
    resourceId: PropTypes.number.isRequired,
};
