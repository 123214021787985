import React from 'react';
import { L } from 'harmony-language';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useAddOrganizationResourceMutation } from '../../../api/mutations/add/use-add-organization-resource-mutation';
import { useEditOrganizationResourceMutation } from '../../../api/mutations/edit/use-edit-organization-resource-mutation';
import { useLocationColumns } from './use-location-columns';
import { ResourcePage } from '../resource-page';
import { EditCreateLocation } from './edit-create-location/edit-create-location';
import { OrganizationLocation, ResourceTypeManagement } from '../../../types';

export const LocationManagement = () => {
    const { data: companyLocations, isLoading } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { mutateAsync: addLocation, isLoading: isCreating } = useAddOrganizationResourceMutation(OrgQueryKeys.locations, L.location());
    const { mutateAsync: editLocation, isLoading: isUpdating } = useEditOrganizationResourceMutation(OrgQueryKeys.locations, L.location());

    const columns = useLocationColumns();

    return (
        <ResourcePage
            resourceType='location'
            isLoading={isLoading || isCreating || isUpdating}
            titleFunc={(location: ResourceTypeManagement) => location.name}
            data={companyLocations}
            columns={columns}
            EditCreateComponent={(editCreateProps: { resource: ResourceTypeManagement, closeModal: () => void }) =>
                <EditCreateLocation
                    existingLocation={editCreateProps.resource as OrganizationLocation}
                    closeModal={editCreateProps.closeModal}
                    addLocation={addLocation}
                    editLocation={editLocation}
                />}
        />
    );
};
