import React, { Dispatch, SetStateAction } from 'react';
import { ProductionCardType } from './production-card';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useProductionCards } from '../use-production-cards';
import { getWorkingWeek } from '../../../utils/date-time-utils';

type BucketId = 'day-bucket' | 'generation-bucket'

export type BucketState = {
    isLoading: boolean,
    dateRange: { from: string, to: string },
    setDateRange: Dispatch<SetStateAction<{ from: string, to: string }>>,
    clear: () => void;
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
    dayBucket: ProductionCardType[];
    generationBucket: ProductionCardType[];
}

type InternalState = Record<string, Record<BucketId, ProductionCardType[]>>

export const useGenerationBuckets = (cargoTypeId: number, options: { disableReordering?: BucketId, initialGenerationData?: ProductionCardType[] } = {}): BucketState => {
    const [dateRange, setDateRange] = React.useState(() => {
        const week0 = getWorkingWeek(0);
        return {
            from: week0.sundayIso,
            to: week0.saturdayIso,
        }
    });
    const [state, setState] = React.useState<InternalState>({});

    const { data: cards, isLoading } = useProductionCards(cargoTypeId, dateRange);
    const key = cargoTypeId;

    React.useEffect(() => {
        if (cards) {
            setState((s) => {
                if (s[key]) {
                    const existingCards = [...s[key]['day-bucket'], ...s[key]['generation-bucket']]
                    const newCards = cards.filter(card => {
                        return !existingCards.find(x => x.id === card.id);
                    });

                    return {
                        ...s,
                        [key]: {
                            'day-bucket': [...s[key]['day-bucket'].filter(card => cards.find(x => x.id === card.id)), ...newCards],
                            'generation-bucket': [...s[key]['generation-bucket'].filter(card => cards.find(x => x.id === card.id))]
                        }
                    };
                } else {
                    return {
                        ...s,
                        [key]: {
                            'day-bucket': [...cards],
                            'generation-bucket': options.initialGenerationData ?? [] // for testing mostly..
                        }
                    };
                }
            });
        }
    }, [cards]);

    const onDragEnd = React.useCallback((result) => {
        const { destination, source, draggableId } = result;

        if (!destination ||
            (
                destination.drobbableId === source.droppableId &&
                destination.index === source.index
            )
        ) {
            return;
        }
        const start = state[key][source.droppableId as BucketId];
        const finish = state[key][destination.droppableId as BucketId];
        const card = start.find(x => x.id == draggableId) as ProductionCardType;


        if (start === finish && options.disableReordering !== source.droppableId) {
            const resortedColumn = start.resortValue(source.index, destination.index, card);
            setState({
                ...state,
                [key]: {
                    ...state[key],
                    [source.droppableId as BucketId]: resortedColumn
                }
            });
        }
        if (start !== finish) {
            const newStart = [...start];
            newStart.splice(source.index, 1);
            const newFinish = [...finish];
            newFinish.splice(destination.index, 0, card);
            setState({
                ...state,
                [key]: {
                    ...state[key],
                    [source.droppableId as BucketId]: newStart,
                    [destination.droppableId as BucketId]: newFinish,
                }
            });
        }

    }, [state, key, options.disableReordering]);

    const clear = () => {
        setState({
            ...state,
            [key]: {
                'day-bucket': [...state[key]['day-bucket'], ...state[key]['generation-bucket']],
                'generation-bucket': []
            }
        });
    };

    return {
        dateRange,
        isLoading,
        setDateRange,
        onDragEnd,
        clear,
        dayBucket: state[key]?.['day-bucket'] ? state[key]['day-bucket'] : [],
        generationBucket: state[key]?.['generation-bucket'] ? state[key]['generation-bucket'] : [],
    };
};
