import React from 'react';
import { CircularProgress } from '@mui/material';
import { DeviceLocation, Driver, OrganizationLocation } from '../../../types';
import { useTractorTrailer } from '../../../api/queries/use-tractor-trailer';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useLoadsInTransit } from '../../../api/queries/use-loads-in-transit';
import { getNextStop } from '../utils/get-next-stop';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import { HereMapMarker } from '../../here-maps/here-map-marker';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';
import { getDriverIcon } from './live-map-driver-icon';
import { LiveMapRouteTooltip } from '../tooltips/live-map-route-tooltip';
import { LiveMapFreemiumTooltip } from '../tooltips/live-map-freemium-tooltip';
import { useWeights } from '../../shared/hooks/use-weights';

const LiveMapTooltip: React.FC<{ isLoading: boolean, children: React.ReactNode }> = (props) => {
    const { isLoading } = props;

    if (isLoading) {
        return (
            <div className='route-info-tooltip'>
                <div style={{ margin: '0 auto' }}>
                    <CircularProgress />
                </div>
            </div>
        );
    }

    return <div>
        {props.children}
    </div>
}

export const DeviceLocationMarker: React.FC<{ deviceLocation: DeviceLocation, isSelected: boolean, onClick: () => void }> = (props) => {
    const { deviceLocation, isSelected, onClick } = props;
    const { tractors, trailers, isLoading: isLoadingEquipment } = useTractorTrailer();
    const { data: drivers, isLoading: isLoadingDrivers } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { data: organizationLocations, isLoading: isLoadingOrganizationLocations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations, { disableOnFreemium: true });
    const { data: loads, isLoading: isLoadingLoads } = useLoadsInTransit();
    const isLoading = isLoadingEquipment || isLoadingDrivers || isLoadingLoads || isLoadingOrganizationLocations;
    const driver = drivers?.find(x => x.id === deviceLocation.userId);
    const driverName = driver ? driver.name : '';
    const tractor = tractors?.find(t => t.id === deviceLocation.tractorId);
    const trailer = trailers?.find(t => t.id === deviceLocation.trailerId);
    const load = loads?.find(l => l.id === deviceLocation.orderId);
    const nextStop = getNextStop(load?.stops);
    const stopLocation = organizationLocations?.find(x => x.id === nextStop?.organizationLocationId);
    const displayName = stopLocation ? toSiteDisplayName(stopLocation.name, stopLocation.description) : '';

    if (nextStop) {
        nextStop.organizationLocation = stopLocation;
        nextStop.displayName = displayName;
    }

    const { convertFromGramsDisplay } = useWeights();

    return (
        <HereMapMarker
            icon={getDriverIcon(deviceLocation.heading || 0, deviceLocation.orderId ? '#ffff00' : '#e62129', driverName)}
            latitude={deviceLocation.latitude}
            longitude={deviceLocation.longitude}
            onClick={onClick}
            tooltipSticky={isSelected}>
            {isSelected
                ?
                <LiveMapTooltip isLoading={isLoading}>
                    {load ?
                        <LiveMapRouteTooltip driver={driver} load={load} nextStop={nextStop} convertFromGramsDisplay={convertFromGramsDisplay} />
                        :
                        <LiveMapFreemiumTooltip deviceLocation={deviceLocation} driver={driver} tractor={tractor} trailer={trailer} />
                    }
                </LiveMapTooltip>
                :
                <div className='route-tooltip-hover'>
                    {driver && <div style={{ paddingBottom: '0.2rem' }}><span>{driverName}</span></div>}
                    <div className='route-tooltip-timestamp'>{localDateTimeDisplay(deviceLocation.timestamp)}</div>
                </div>
            }
        </HereMapMarker>
    );
}
