import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DragHandle from "@mui/icons-material/DragHandle";
import { IconButton, styled, Typography } from "@mui/material";
import { ResourceType } from "harmony-constants";
import { L } from "harmony-language";
import React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { verticalLockDragStyle } from "../../shared/component-utils";
import { Select } from "../../shared/inputs/select";

interface TemplateEditorProps {
    templateItemsArray: string[];
    resourceType: string;
    updateTemplate: (reorderedValues: string[]) => void;
}

const TemplateItemsContainer = styled('div')({
    marginBottom: '0.5rem',
    border: '1px solid #e0e0e0 ',
    fontSize: '.875rem',
    maxHeight: '45em',
    overflowY: 'scroll'
});

const Header = styled('div')({
    backgroundColor: '#eee',
    padding: '8px 8px',
    position: 'sticky',
    top: 0,
    zIndex: 1
})

const tractorItems = [
    "checklistItemTractorAdequateTreadDepthLeft",
    "checklistItemTractorAdequateTreadDepthRight",
    "checklistItemTractorAllDoorsOpenAndCloseFully",
    "checklistItemTractorAllFittedWorkingCleanAndNotDamaged",
    "checklistItemTractorAllLightsWorkCorrectlyIncludingHazardLights",
    "checklistItemTractorAllSwitchesAndWarningLightsOperateCorrectly",
    "checklistItemTractorAugerCradleIndicatorLightUnobstructedAndworking",
    "checklistItemTractorFireExtinguisherServicedAndReadyForUse",
    "checklistItemTractorGreaseBoomAugerBearings",
    "checklistItemTractorGreaseBoomCylinderPins",
    "checklistItemTractorGreaseBoomPivot",
    "checklistItemTractorGreaseLowerVerticalRotation",
    "checklistItemTractorGreaseTroughAugerBearings",
    "checklistItemTractorGreaseUpperVerticalBearingClamp",
    "checklistItemTractorGreaseVerticalAugerBearings",
    "checklistItemTractorGreaseVerticalRotationPilotRing",
    "checklistItemTractorHornWorkingCorrectly",
    "checklistItemTractorInspectAndGreaseFifthWheel",
    "checklistItemTractorLensesAreCleanAndNotCracked",
    "checklistItemTractorLicensePlateRegistration",
    "checklistItemTractorLubeChain",
    "checklistItemTractorNoApparentAirLeaksFromBreaks",
    "checklistItemTractorNoCutsOrBulgesInTireSideWallsLeft",
    "checklistItemTractorNoCutsOrBulgesInTireSideWallsRight",
    "checklistItemTractorNoFluidLeaksFromEngineFuelSystemOrWastewaterTankLeft",
    "checklistItemTractorNoFluidLeaksFromEngineFuelSystemOrWastewaterTankRight",
    "checklistItemTractorSeatbeltsWorkingCorrectly",
    "checklistItemTractorSideBodyPictureLeft",
    "checklistItemTractorSideBodyPictureRight",
    "checklistItemTractorSpeedometerWorkingCorrectly",
    "checklistItemTractorSteeringForExcessiveFreeMovement",
    "checklistItemTractorSufficientFuelToCompleteTrip",
    "checklistItemTractorTiresAreCorrectlyInflatedAndNotDamaged",
    "checklistItemTractorTiresAreCorrectlyInflatedAndNotDamagedLeft",
    "checklistItemTractorWasherFluidContainerFull",
    "checklistItemTractorWheelNutsAreTightLeft",
    "checklistItemTractorWheelNutsAreTightRight",
    "checklistItemTractorWheelsNotDamagedLeft",
    "checklistItemTractorWheelsNotDamagedRight",
    "checklistItemTractorWindshieldCleanAndNotCracked",
    "checklistItemTractorWindshieldWashersWorkCorrectly",
    "checklistItemTractorWiperBladesNotDamaged",
    "checklistItemTractorWipersWorkCorrectly",
]

const trailerItems = [
    "checklistItemTrailerAirConnection",
    "checklistItemTrailerAirLines",
    "checklistItemTrailerAllPaperwork",
    "checklistItemTrailerAuditoryAlarm",
    "checklistItemTrailerBackupLights",
    "checklistItemTrailerBearings",
    "checklistItemTrailerBoom",
    "checklistItemTrailerBoomLightsAndWiring",
    "checklistItemTrailerBoomSock",
    "checklistItemTrailerBrakes",
    "checklistItemTrailerCabMonitor",
    "checklistItemTrailerCheckHydraulicOil",
    "checklistItemTrailerCorneringLights",
    "checklistItemTrailerCracksOnUprightBoom",
    "checklistItemTrailerCracksSuspensionBogieTank",
    "checklistItemTrailerCrossOver",
    "checklistItemTrailerDoors",
    "checklistItemTrailerDOTTape",
    "checklistItemTrailerDrainAirReservoirs",
    "checklistItemTrailerDriverPPE",
    "checklistItemTrailerEngine1",
    "checklistItemTrailerEngine2",
    "checklistItemTrailerFloor",
    "checklistItemTrailerGauges",
    "checklistItemTrailerHeatingCooling",
    "checklistItemTrailerHeightTravelMode",
    "checklistItemTrailerHighPressureFilter",
    "checklistItemTrailerHoses",
    "checklistItemTrailerHydraulicReservoirs",
    "checklistItemTrailerInteriorCondition",
    "checklistItemTrailerInteriorExteriorLights",
    "checklistItemTrailerIsClean",
    "checklistItemTrailerLeaks",
    "checklistItemTrailerLicensePlateRegistration",
    "checklistItemTrailerLiftgate",
    "checklistItemTrailerLugNutsTorque",
    "checklistItemTrailerMarkerLights",
    "checklistItemTrailerMonitorNumber",
    "checklistItemTrailerOilLevel",
    "checklistItemTrailerPressureCheckWetKitsPumps",
    "checklistItemTrailerRoofLids",
    "checklistItemTrailerRotate",
    "checklistItemTrailerSeals",
    "checklistItemTrailerSplinesPulledOutAndGreased",
    "checklistItemTrailerSpringsTieDownsStTrucks",
    "checklistItemTrailerSprockets",
    "checklistItemTrailerStubShaft",
    "checklistItemTrailerTankFilters",
    "checklistItemTrailerTargetTemperature",
    "checklistItemTrailerTarps",
    "checklistItemTrailerTensioner",
    "checklistItemTrailerTiresCheckAirPressure",
    "checklistItemTrailerTiresWheels",
    "checklistItemTrailerUpright",
    "checklistItemTrailerV20HandlesAndValves",
    "checklistItemTrailerVentTemps",
    "checklistItemTrailerWorkLights",
]

export const ChecklistTemplateItemsEditor = (props: TemplateEditorProps) => {
    const { templateItemsArray, resourceType, updateTemplate } = props;

    const selectItems = resourceType === ResourceType.Tractor
        ? tractorItems.map(item => ({id: item, label: L[item]()}))
        : trailerItems.map(item => ({id: item, label: L[item]()}));

    function handleOnDragEnd(result: DropResult) {
        if (!result.destination) return;

        const items = Array.from(templateItemsArray);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateTemplate(items);
    }

    return (
        <div>
            <Header>
                <Typography>{L.checklistItems()}</Typography>
            </Header>
            <TemplateItemsContainer>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="checklistTemplate">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {templateItemsArray.map((x, i) => (
                                    <Draggable draggableId={String(i)} index={i} key={i}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={verticalLockDragStyle(provided.draggableProps.style)}
                                            >
                                                <div style={{display: 'flex', flexDirection: 'row', height: '3em', alignItems: 'center'}}>
                                                    <DragHandle color='primary' sx={{mx: 1}}/>
                                                    <Select
                                                        list={selectItems}
                                                        required
                                                        item={selectItems.find(selectItem => selectItem.id === x)}
                                                        onChange={(newlySelected) => {
                                                            const items = Array.from(templateItemsArray);
                                                            items.splice(i, 1);
                                                            items.splice(i, 0, newlySelected.id);

                                                            updateTemplate(items);
                                                        }}
                                                    />
                                                    <IconButton onClick={() => {
                                                        const items = Array.from(templateItemsArray);
                                                        items.splice(items.indexOf(x), 1);

                                                        updateTemplate(items);
                                                    }}>
                                                        <DeleteOutlinedIcon color="primary"/>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                <IconButton
                                        title={L.add()}
                                        size='small'
                                        color='primary'
                                        onClick={() => {
                                            const items = Array.from(templateItemsArray);
                                            items.push('');

                                            updateTemplate(items);
                                        }}
                                    >
                                        <AddBoxOutlinedIcon color='primary' />
                                </IconButton>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </TemplateItemsContainer>
        </div>
    )
}