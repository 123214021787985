import React from 'react';
import { StopProvider } from '../stop-context';
import { Load, Stop } from '../../../types';
import { IconButton } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { verticalLockDragStyle } from '../component-utils';
import DragHandle from '@mui/icons-material/DragHandle';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LoadTableColumn } from './load-table';
import { L } from 'harmony-language';
import { StopCompartments, StopCustomerOrderNumber, StopLoadingTeam, StopRequiredTimestamp } from './stops-table-cells';
import { StopsTableGridRow, StopsTableGridRowAnother, StopsTableRowPrefix, gridRowEditable } from './stops-table';
import { VisibilityIconButton } from './visibility-icon-button';

interface EditableStopsTableRowProps {
    load: Load;
    stop: Stop;
    i: number;
    isSaving?: boolean;
    currentUserIsCarrierOrg: boolean;
    columns: LoadTableColumn[];
    deleteStop: (stop: Stop) => void;
    handleVisChange: (i: number, rowVis: boolean) => void;
}

export const EditableStopsTableRow: React.FC<EditableStopsTableRowProps> = (props) => {
    const { load, stop, i, isSaving, currentUserIsCarrierOrg, columns, deleteStop, handleVisChange } = props;
    const [secondRowVis, setSecondRowVis] = React.useState(false);

    React.useEffect(() => {
        handleVisChange(i, secondRowVis);
    }, [secondRowVis]);

    return (
        <StopProvider key={stop.sequence} stop={stop}>
            <Draggable draggableId={`stop-${stop.sequence}`} index={i}
                        isDragDisabled={isSaving || currentUserIsCarrierOrg}>
                {(draggableProvided) => (
                    <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}
                            style={verticalLockDragStyle(draggableProvided.draggableProps.style)}>
                        <StopsTableRowPrefix>
                            <DragHandle style={{ display: 'flex', margin: 'auto' }} color={currentUserIsCarrierOrg ? 'disabled' : 'primary'} />
                            <VisibilityIconButton
                                secondRowVis={secondRowVis}
                                stop={stop}
                                onClick={setSecondRowVis} />
                            <StopsTableGridRow sx={gridRowEditable}>
                                {columns.map((c, i) => {
                                    return (
                                        <div key={i}>
                                            <c.render required={c.required} validations={c.validations} />
                                        </div>
                                    );
                                })}
                                <IconButton
                                    title={L.delete()}
                                    edge='start'
                                    size='small'
                                    color='primary'
                                    disabled={load.stops.filter(s => s.splitId === stop.id).length > 0 || currentUserIsCarrierOrg}
                                    onClick={() => deleteStop(stop)}
                                >
                                    <DeleteOutlinedIcon color={load.stops.filter(s => s.splitId === stop.id).length > 0 || currentUserIsCarrierOrg ? 'disabled' : 'primary'} />
                                </IconButton>
                                </StopsTableGridRow>
                        </StopsTableRowPrefix>
                        {secondRowVis ?
                            <StopsTableRowPrefix>
                                <div />
                                <div />
                                <StopsTableGridRowAnother sx={{ borderTop: '1px solid transparent' }}>
                                    <div><StopCustomerOrderNumber /></div>
                                    <div><StopLoadingTeam /></div>
                                    <div><StopRequiredTimestamp colName='requiredBegin' /></div>
                                    <div><StopRequiredTimestamp colName='requiredEnd' /></div>
                                    <div><StopCompartments /></div>
                                </StopsTableGridRowAnother>
                            </StopsTableRowPrefix>
                        : null}
                    </div>
                )}
            </Draggable>
        </StopProvider>
    );
};
