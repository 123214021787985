import { getCurrentUnitSystem, UNITS } from 'harmony-language';

export const METERS_TO_MILES_CONVERSION_RATE = 0.000621371;

// Haversine
export const getDistanceInKilometers = (pointA, pointB) => {
    const deg2rad = Math.PI / 180;
    const lat1 = pointA.latitude * deg2rad;
    const lon1 = pointA.longitude * deg2rad;
    const lat2 = pointB.latitude * deg2rad;
    const lon2 = pointB.longitude * deg2rad;
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;
    const a = (
        (1 - Math.cos(dLat)) +
        (1 - Math.cos(dLon)) * Math.cos(lat1) * Math.cos(lat2)
    ) / 2;

    const diameter = 12742; // Diameter of the earth in km (2 * 6371)

    return diameter * Math.asin(Math.sqrt(a));
};

// points are 2-element arrays [lat, long]
export const getDistance = (pointA, pointB) => {
    const distanceKm = getDistanceInKilometers(pointA, pointB);

    return getCurrentUnitSystem() === UNITS.Metric ? distanceKm : distanceKm * 1000 * METERS_TO_MILES_CONVERSION_RATE;
};

/**
 * 
 * @param {number} lat1 initial latitude, in degrees
 * @param {number} lon1 initial longitude, in degrees
 * @param {number} distance target distance from initial
 * @param {number} bearing heading in degrees
 * @param {number} radiusOfEarth defaults to mean radius of earth in meters
 * @returns Returns new lat/lon coordinate {distance} meters from initial, in degrees
 */
const getPointFromDistanceBearing = (lat1, lon1, distance, bearing, radiusOfEarth = 6371000) => {
    //helpers for math
    const radians = (degrees) => degrees * (Math.PI / 180);
    const degrees = (radians) => radians * (180 / Math.PI);
    const asin = Math.asin;
    const cos = Math.cos;
    const sin = Math.sin;
    const atan2 = Math.atan2;

    lat1 = radians(lat1)
    lon1 = radians(lon1)
    const a = radians(bearing)
    const lat2 = asin(sin(lat1) * cos(distance/radiusOfEarth) + cos(lat1) * sin(distance/radiusOfEarth) * cos(a))
    const lon2 = lon1 + atan2(
        sin(a) * sin(distance/radiusOfEarth) * cos(lat1),
        cos(distance/radiusOfEarth) - sin(lat1) * sin(lat2)
    )
    return {lat: degrees(lat2), lng: degrees(lon2)}
}

/**
 * 
 * @param {{latitude: number, longitude: number}} point 
 * @param {} meters length of one side of the square, in meters
 * @returns 
 */
export const pointToBbox = (point, meters = 250) => {
    const halfHypotenuse = Math.sqrt(meters**2 + meters**2) / 2;
    const {lat: topLeftLat, lng: topLeftLng} = getPointFromDistanceBearing(point.latitude, point.longitude, halfHypotenuse, 315);
    const {lat: bottomRightLat, lng: bottomRightLng} = getPointFromDistanceBearing(point.latitude, point.longitude, halfHypotenuse, 135)
    return {
        topLeft: {
            latitude: topLeftLat,
            longitude: topLeftLng,
        },
        bottomRight: {
            latitude: bottomRightLat,
            longitude: bottomRightLng,
        }   
    };
};
