import { Button, MenuItem, Select, Typography } from '@mui/material';
import { L } from 'harmony-language';
import React from 'react';
import { ReactTableColumns, ReportRunOptions } from './report-types';
import { Table } from '@tanstack/react-table';

interface ReportPagingProps<T> {
    table: Table<T>;
    runReport: (r: ReportRunOptions) => void;
}

export const ReportPaging = <T extends ReactTableColumns>(props: ReportPagingProps<T>) => {
    const { table, runReport } = props;

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
            <span>
                <Typography style={{ display: 'inline' }}>{L.totalRecords(table.getRowCount().toString())}</Typography>
                <Button
                    size='small'
                    variant='outlined'
                    style={{ marginLeft: '10px' }}
                    onClick={() => runReport({ csv: true })}
                    disabled={!table.getRowCount()}
                >
                    CSV
                </Button>
            </span>
            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography style={{ marginRight: '10px' }}>{L.rowsPerPage()}</Typography>
                <Select
                    variant='standard'
                    disableUnderline
                    defaultValue={50}
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value))
                    }}>
                    {[5, 10, 25, 50, 100].map(pageSize => (
                        <MenuItem key={pageSize} value={pageSize}>{pageSize}</MenuItem>
                    ))}
                </Select>
                <span className='' style={{ marginLeft: '10px', marginRight: '10px' }}>
                    <span>{L.page()} {' '}</span>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of{' '}
                        {table.getPageCount().toLocaleString()}
                    </strong>
                </span>
                <Button
                    size='small'
                    variant='outlined'
                    onClick={() => table.firstPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<<'}
                </Button>
                <Button
                    size='small'
                    variant='outlined'
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<'}
                </Button>
                <Button
                    size='small'
                    variant='outlined'
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    {'>'}
                </Button>
                <Button
                    size='small'
                    variant='outlined'
                    onClick={() => table.lastPage()}
                    disabled={!table.getCanNextPage()}
                >
                    {'>>'}
                </Button>
                {/* works, but not sure we need?
                <span className=''>
                    | Go to page:
                        <input
                            type='number'
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                            }}
                            className=''
                        />
                </span> */}
            </span>
        </div>
    );
};
