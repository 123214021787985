import React from 'react';
import ProductionBucket from './production-bucket';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useGenerationBuckets } from './use-generation-buckets';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import { ModalDialog } from '../../shared/containers/modal-dialog';
import { useBool } from '../../shared/hooks/use-bool';
import ProductionPreview from '../preview/production-preview';
import { AgisticsDateRange } from '../../shared/inputs/agistics-date-range';

interface ProductionGenerationProps {
    cargoTypeId: number;
}

const ProductionGeneration: React.FC<ProductionGenerationProps> = (props) => {
    const {
        isLoading,
        onDragEnd,
        dayBucket,
        generationBucket,
        clear,
        dateRange,
        setDateRange,
    } = useGenerationBuckets(props.cargoTypeId);
    const [isOpen, { off: close, on: open }] = useBool(false);

    return (
        <div>
            <ModalDialog title={L.previewLoads()} onClose={close}
                open={isOpen && generationBucket.length !== 0}>
                <ProductionPreview cargoTypeId={props.cargoTypeId} productionCardIds={generationBucket.map(b => b.id)} onGenerateSuccess={() => {
                    close();
                }} />
            </ModalDialog>
            <div style={{ padding: '1rem 0 0 0', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{ maxWidth: '20rem' }}>
                    <AgisticsDateRange
                        startDate={dateRange.from}
                        endDate={dateRange.to}
                        setStartDate={(date) => setDateRange({ ...dateRange, from: date })}
                        setEndDate={(date) => setDateRange({ ...dateRange, to: date })}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={'day-bucket'}>
                        {(provided , snapshot) =>
                            <ProductionBucket cards={dayBucket} {...provided} {...snapshot} isLoading={isLoading} />
                        }
                    </Droppable>
                    <Droppable droppableId={'generation-bucket'}>
                        {(provided, snapshot) =>
                            <ProductionBucket cards={generationBucket} disableSearch {...provided} {...snapshot}>
                                <div style={{ padding: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button type='button'
                                        title={L.clear()}
                                        variant='contained'
                                        color='secondary'
                                        sx={{ margin: '0 0.5rem' }}
                                        onClick={clear}
                                    >{L.clear()}</Button>
                                    <Button type='button'
                                        disabled={isLoading || generationBucket.length === 0}
                                        title={L.preview()}
                                        variant='contained'
                                        color='primary'
                                        sx={{ margin: '0 0.5rem' }}
                                        onClick={open}
                                    >{L.preview()}</Button>
                                </div>
                            </ProductionBucket>
                        }
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
};

export default ProductionGeneration;
