import React from 'react';
import { OrganizationLocation, TripEvent } from '../../../types';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';
import { L } from 'harmony-language';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import './delivery-log.css';
import { AgisticsWarningIcon } from '../../shared/agistics-warning-icon';

export const translatedTypeString = (event: TripEvent, locations: OrganizationLocation[]) => {
    let translatedTypeString = L[`tripEventType${event.type}`]();
    if (event.type === 'SiteArrived' || event.type === 'SiteLeft') {
        // mobile does not use the concept of initiatingId / reporting multiple orgLocations on events
        const searcherId = event.userId ? event.organizationLocationId : event.initiatingId;
        const site = locations?.find(x => x.id === searcherId);
        translatedTypeString += ' ' + (site ? toSiteDisplayName(site.name, site.description) : '');
    } else if (event.type === 'SublocationArrived' || event.type === 'SublocationLeft') {
        const orgSublocation = locations.find(location => {
            const sub = location.orgSubLocations.find(sublocation => sublocation.id === event.initiatingId);
            return sub;
        })?.orgSubLocations.find(sublocation => sublocation.id === event.initiatingId);

        if (orgSublocation) {
            translatedTypeString += ' (' + orgSublocation.name + ')';
        }
    }

    return translatedTypeString;
}

interface DeliveryLogEventRowProps {
    event: TripEvent;
    i: number;
    locations: OrganizationLocation[];
    moveMap: (lat: number, lng: number) => void;
}

export const DeliveryLogEventRow: React.FC<DeliveryLogEventRowProps> = (props) => {
    const { event, i, locations, moveMap } = props;

    const hasMetadataComment = Boolean(event.metadata?.comment);

    return (
        <div key={i} className='sharedGridRow eventsGridRow' onClick={() => moveMap(event.latitude, event.longitude)}>
            <span className='sharedGridItem' title={event.timestamp.toString()}>{localDateTimeDisplay(event.timestamp)}</span>
            <span className='sharedGridItem'>
                {event.accuracy === 0 && <AgisticsWarningIcon message={L.eventDataAccuracy()} />}
                {translatedTypeString(event, locations)}
                {hasMetadataComment ? ` - ${event.metadata?.comment}` : ''}
            </span>
        </div>
    );
};
