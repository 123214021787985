import { OrganizationLocation } from '../../../../types';
import React from 'react';
import TextField from '@mui/material/TextField';
import { L } from 'harmony-language';

type DescriptionInputProps = {
    orgLocation: OrganizationLocation,
    onOrgLocationChange: (orgLocation: OrganizationLocation) => void;
}

export const DescriptionInput: React.FC<DescriptionInputProps> = (props) => {
    const { orgLocation, onOrgLocationChange } = props;

    return (
        <TextField
            variant='standard'
            label={L.description()}
            id='site-description'
            value={orgLocation.description || ''}
            style={{ width: '100%' }}
            onChange={(e) => {
                onOrgLocationChange({ ...orgLocation, description: e.target.value });
            }}
            inputProps={{ maxLength: 200 }} />
    );
};
