import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, OrgQueryKeys, QueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Resource } from '../../../types';
import { useCompanyId } from '../../../app/agistics-context';
import { useUser } from '../../queries/use-user';

export const useEditOrganizationResourceMutation = (queryKey: string, resourceName: string) => {
    const snackbar = useSnackbar();
    const companyId = useCompanyId();
    const key = OrgQueryKeys.resolve(companyId, queryKey);
    const queryClient = useQueryClient();
    const { user } = useUser();

    return useMutation(async (resource) => {
        resource.organizationId = companyId;
        return (await axios.put(`${API_BASE}/api${key}/${resource.id}`, resource)).data;
    }, {
        ...mutationOptions([key], queryClient),
        onSuccess: (data) => {
            const resources = queryClient.getQueryData<Resource[]>([key]);
            const updatedResource = resources?.map((existingResource) => {
                if (existingResource.id === data.id) {
                    return {
                        ...data,
                    };
                }
                return existingResource;
            });

            queryClient.setQueryData([key], updatedResource);
            snackbar(L.updatedMessage(resourceName));

            // if editing users and isEditingSelf
            // something in /api/self may have changed.
            if (queryKey === OrgQueryKeys.users) {
                if (data.id === user.id) {
                    queryClient.invalidateQueries([QueryKeys.self]);
                }
            }

            // user was potentially enabled/disabled, needs filted in/out of notification config select now
            queryClient.invalidateQueries([`/organizations/${companyId}${OrgQueryKeys.contacts}`]);
        }
    });
};
