import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { createColumnHelper } from '@tanstack/react-table';
import { Features } from 'harmony-constants';
import { L } from 'harmony-language';
import PropTypes from 'prop-types';
import React from 'react';
import { VIEW_NOTIFCATION_CONFIGS_PERMISSION, resourcePermission } from '../../permissions/permissions';
import { capitalizeFirst } from '../../utils/string-utils';
import { ResourceDowntime } from '../downtime/resource-downtime';
import { Content } from '../shared/containers/content';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { useBoolean } from '../shared/hooks/use-boolean';
import { ResourceTable } from '../shared/resource-table/resource-table';
import { ActionIcon } from '../shared/table/components/action-icon';
import { useCarrier, useFeatures, usePermissions } from '../user/selectors/use-permissions';
import { UserNotificationPreferences } from '../user/user-notification-preferences';
import { AdminBreadCrumbHeader } from './admin-bread-crumb-header';

const useResourceColumns = (mainColumns, actions) => {
    const columnHelper = createColumnHelper();

    return React.useMemo(
        () => {
            return [
                ...mainColumns,
                columnHelper.accessor('actions', {
                    header: L.actions(),
                    cell: (info) => {
                        const row = info.row.original;
                        return (
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {actions?.map(a => {
                                    // used for userHasEmail
                                    const disabled = a?.condition ? !(a.condition(row)) : false;

                                    return (
                                        <ActionIcon
                                            key={a.icon}
                                            action={() => a.action(row)}
                                            isDisabled={disabled}
                                            icon={a.icon}
                                            tooltip={a.tooltip}
                                            testId={row.id} />
                                    )
                                })}
                            </div>
                        )
                    },
                    size: 150,
                    enableSorting: false,
                    enableColumnFilter: false
                }),
            ];
        }, [mainColumns, actions]
    );
};


export const ResourcePage = (props) => {
    const { columns, data, additionalActions, resourceType, isLoading, titleFunc } = props;
    const [modalOpen, openModal, closeModal] = useBoolean(false);
    const [showNotificationPrefs, openNotificationModal, closeNotificationModal] = useBoolean(false);
    const [modalType, setModalType] = React.useState('editCreate');
    const [resource, setResource] = React.useState(null);
    const canAccessDowntime = useFeatures(Features.Downtime);
    const nonDowntimeResources = ['serviceAccount', 'customer', 'contact', 'checklistTemplate']
    const showDowntime = canAccessDowntime && !nonDowntimeResources.includes(resourceType);
    const createResourcePermission = usePermissions(resourcePermission(resourceType).create);
    const editResourcePermission = usePermissions(resourcePermission(resourceType).edit);
    const currentUserIsCarrier = useCarrier();
    const viewContactPreferences = usePermissions(VIEW_NOTIFCATION_CONFIGS_PERMISSION);

    const handleModalOpen = (type) => (row) => {
        setModalType(type);
        setResource(row);
        openModal();
    };

    const actions = React.useMemo(() => {
        const actions = [];

        if (editResourcePermission) {
            const editAction = {
                icon: 'edit',
                tooltip: L.edit(),
                action: handleModalOpen('editCreate')
            };

            actions.push(editAction);
        }

        if (showDowntime) {
            const downtimeAction = {
                icon: 'event_busy',
                tooltip: L.downtime(),
                action: handleModalOpen('downtime')
            };

            actions.push(downtimeAction);
        }

        if ((resourceType === 'user' || resourceType === 'contact') && !currentUserIsCarrier && viewContactPreferences) {
            const editContactPreferenceAction = {
                icon: 'sms',
                tooltip: L.editContactPreferences(),
                action: (row) => {
                    setResource(row);
                    openNotificationModal();
                }
            }
            actions.push(editContactPreferenceAction);
        }

        if (additionalActions) {
            actions.push(...additionalActions);
        }

        return actions;
    }, [additionalActions, showDowntime]);

    const tableColumns = useResourceColumns(columns, actions);

    const options = React.useMemo(() => ({
        pageSize: 10,
        sortBy: tableColumns.filter(x => x.defaultSortBy).map(x => ({ id: x.id }))
    }), [tableColumns]);

    const title = (resource && titleFunc) ? `${L[resourceType]()} - ${titleFunc(resource)}` : L[resourceType]();

    return (
        <>
            <AdminBreadCrumbHeader>
                {createResourcePermission && <Button variant='contained' color='primary' onClick={() => {
                    handleModalOpen('editCreate')(null);
                }}><Add /> {L[`create${capitalizeFirst(resourceType)}`]()}</Button>}
            </AdminBreadCrumbHeader>
            <ModalDialog open={modalOpen} onClose={closeModal} title={title} fullWidth={resourceType === 'location' && modalType === 'editCreate'} draggable={resourceType !== 'checklistTemplate'}>
                <div>
                    {modalType === 'editCreate' &&
                        <props.EditCreateComponent closeModal={closeModal} resource={resource} />
                    }
                    {modalType === 'downtime' &&
                        <ResourceDowntime resourceType={`${resourceType}s`} resourceId={resource.id} />
                    }
                </div >
            </ModalDialog>
            {showNotificationPrefs && <UserNotificationPreferences contactId={resource.contactId || resource.id} organizationId={resource.organizationId} closePreferences={closeNotificationModal} />}
            <Content>
                <ResourceTable
                    id={resourceType}
                    title={L[`${resourceType}s`]()}
                    isLoading={isLoading}
                    columns={tableColumns}
                    data={data || []}
                    options={options}
                />
            </Content>
        </>
    );
};

ResourcePage.propTypes = {
    columns: PropTypes.array.isRequired,
    resourceType: PropTypes.string.isRequired,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    additionalActions: PropTypes.array,
    EditCreateComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    titleFunc: PropTypes.func,
};
