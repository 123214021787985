import React from 'react';
import PropTypes from 'prop-types';
import { HereMap } from '../../../here-maps/here-map';
import { HereMapMarker } from '../../../here-maps/here-map-marker';
import { HereMapRectangle } from '../../../here-maps/here-map-rectangle';
import { getDistance, pointToBbox } from '../../../../utils/geo-utils';
import { Button } from '@mui/material';
import { SublocationCircle } from '../../../here-maps/sublocation-circle';
import { useOrgLocationColorProxy } from '../../../here-maps/use-org-location-color-proxy';

export const LocationMap = ({ orgLocation, geofence, changeGeofence, setDistanceBetween, onOrgLocationChange, organization, isLoading }) => {
    const [sublocationIndex, setSublocationIndex] = React.useState(null);

    const onClick = (coords) => {
        if (!orgLocation.location) {
            onOrgLocationChange({
                ...orgLocation,
                location: {
                    geoLocation: {
                        latitude: coords.lat,
                        longitude: coords.lng,
                    },
                    address: {
                        address1: null,
                        address2: null,
                        city: null,
                        country: null,
                        postalCode: null,
                        premiseId: null,
                        state: null
                    }
                }
                
            });
        }

        const point = {
            latitude: coords.lat,
            longitude: coords.lng,
        };

        if (sublocationIndex !== null) {
            const changed = [...orgLocation.orgSubLocations];
            const oldSublocation = changed.at(sublocationIndex);
            const updatedSublocation = {
                ...oldSublocation,
                customGeofence: {
                    id: oldSublocation.customGeofenceId,
                    ...point,
                }
            }
            changed[sublocationIndex] = updatedSublocation;
            onOrgLocationChange({
                ...orgLocation,
                orgSubLocations: changed,
            });
            setSublocationIndex(null);
        } else {
            changeGeofence({
                ...point,
                bbox: pointToBbox(point)
            });
            setDistanceBetween(calculateDistance(coords.lat, coords.lng));
        }
    };

    const calculateDistance = (newLat, newLng) => {
        if (!orgLocation?.location?.geoLocation?.latitude || !geofence?.latitude) {
            return 0;
        }

        return getDistance(orgLocation.location.geoLocation, { latitude: newLat, longitude: newLng });
    };

    const locationMarker = () => {
        if (!orgLocation.location?.geoLocation) {
            return null;
        }
        const latitude = orgLocation.location.geoLocation.latitude;
        const longitude = orgLocation.location.geoLocation.longitude;

        return <HereMapMarker latitude={latitude} longitude={longitude} recenterOnChange={true} />;
    };

    const onShapeChanged = (changedShape) => {
        changeGeofence(changedShape);
        setDistanceBetween(calculateDistance(changedShape.latitude, changedShape.longitude));
    };

    const locationShape = () => {
        if (!geofence?.bbox) {
            return null;
        }

        const { bbox } = geofence;
        const topLeft = { lat: bbox.topLeft.latitude, lng: bbox.topLeft.longitude };
        const bottomRight = { lat: bbox.bottomRight.latitude, lng: bbox.bottomRight.longitude };

        return (
            <HereMapRectangle
                pointA={topLeft}
                pointB={bottomRight}
                strokeColor='none'
                fillColor={useOrgLocationColorProxy()[orgLocation.orgLocationType?.id].fill}
                type='site'
                changeable={true}
                onChanged={onShapeChanged}
            />
        );
    };

    const sublocationCircles = () => {
        const circles = orgLocation?.orgSubLocations?.map((x, i) => {
            if (x?.customGeofence) {
                return (
                    <SublocationCircle
                        organizationSubLocation={x}
                        key={i} />
                )
            }
            return null;
        });
        return circles;
    }

    const tagLocationButtons = () => {
        if (orgLocation?.orgSubLocations) {
            const buttons = orgLocation.orgSubLocations.map((x, i) => {
                if (x.name !== '') {
                    return (
                        <Button
                            key={i}
                            sx={{ marginTop: '1em', left: '1em', zIndex: 10 }}
                            variant='contained'
                            color='inherit'
                            onClick={() => setSublocationIndex(i)}
                        >{x.name}</Button>
                    )
                }
            });
            return <div style={{ position: 'absolute', display: 'grid' }}>{buttons}</div>;
        }
    }

    if (isLoading) {
        return null;
    }

    return (
        <HereMap className='location-map' id='location-map' defaultZoom={14} onClick={onClick}
            centerPosition={{ lat: organization.latitude, lng: organization.longitude }}
        >
            {locationMarker()}
            {locationShape()}
            {tagLocationButtons()}
            {sublocationCircles()}
        </HereMap>
    );
};

LocationMap.propTypes = {
    orgLocation: PropTypes.object,
    geofence: PropTypes.object,
    changeGeofence: PropTypes.func,
    setDistanceBetween: PropTypes.func,
    onOrgLocationChange: PropTypes.func,
    organization: PropTypes.object,
    isLoading: PropTypes.bool,
};
