import React from 'react';
import {
    filterStopByDestination,
    filterStopByOrigin,
    orderToProductionPlans,
    orderToSalmonellaResult
} from '../../../utils/data-mapping-utils';
import { recalculateStopTimeDrafts } from './cascade-utils';
import { sortByDate } from '../../shared/table/utils/sorting-functions';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useTravelTimesCache } from '../../travel-times/use-travel-times';


export const CascadeIntervals = [
    {id: 15, label: '15m'},
    {id: 30, label: '30m'},
    {id: 45, label: '45m'},
    {id: 60, label: '1h'},
    {id: 75, label: '1h 15m'},
    {id: 90, label: '1h 30m'}
];

const createReducer = (drafts, travelTimes, throughputs) => (state, action) => {
    let newState;
    switch (action.type) {
        case 'date':
            newState = {
                ...state,
                date: action.value
            };
            break;
        case 'direction':
            newState = {
                ...state,
                isBackwards: !state.isBackwards
            };
            break;
        case 'basis':
            newState = {
                ...state,
                basis: action.value,
                date: resolveDate({...state, drafts, basis: action.value})
            };
            break;
        case 'interval':
            newState = {
                ...state,
                interval: action.value
            };
            break;
        case 'offsets':
            newState = {
                ...state,
                offsets: state.offsets.map((x, i) => action.index === i ? action.value : x),
            };
            break;
        case 'reorder':
            newState = {
                ...state,
                drafts: action.value
            };
            break;
        case 'init':
        default:
            newState = state;
    }
    return {
        ...newState,
        drafts: recalculateStopTimeDrafts({
            drafts: newState.drafts,
            throughputs,
            travelTimes,
            stopLocationBasis: newState.basis,
            intervalMinutes: newState.interval.id,
            startAtDate: newState.date,
            isBackwards: newState.isBackwards,
            offsets: newState.offsets
        }) || state.drafts
    };
};

const resolveDate = (state) => {
    const stops = state.drafts.map(d => d.stops?.find(s => s.organizationLocationId === state.basis?.id)).filter(Boolean);
    const stopArrivalTimes = stops.map(s => ({date: s.arrivalTime})).sort(sortByDate);
    if (state.isBackwards) {
        stopArrivalTimes.reverse()
    }
    const x = stopArrivalTimes[0]?.date || state.date;
    return x
};

export const useCascadeStops = (drafts, stopBasii) => {
    const travelTimes = useTravelTimesCache();
    const {data: throughputs} = useOrganizationQuery(OrgQueryKeys.throughput);

    const reducer = React.useCallback(createReducer(drafts, travelTimes, throughputs), [drafts, travelTimes, throughputs]);
    const [state, dispatch] = React.useReducer(reducer, {
        drafts,
        offsets: Array(drafts.length).fill(0),
        date: null,
        basis: stopBasii?.length === 1 ? stopBasii[0] : null,
        interval: CascadeIntervals[3],
        isBackwards: false
    }, (initialArg) => {
        const newDate = resolveDate(initialArg);
        return reducer({...initialArg, date: newDate}, {type: 'init'});
    });

    const [hasMultiStop, setHasMultiStop] = React.useState(false);

    React.useEffect(() => {
        const hasMultiOrigin = drafts.some(d => d.stops.filter(filterStopByOrigin).length > 1);
        const hasMultiDestination = drafts.some(d => d.stops.filter(filterStopByDestination).length > 1);

        setHasMultiStop(state.basis && (hasMultiOrigin || hasMultiDestination));
    }, [state.basis, drafts]);

    const dropFunction = (update) => {
        const reorderedDrafts = [...state.drafts];

        if (!update.destination) {
            return reorderedDrafts;
        }

        const dragIndex = update.source.index;
        const hoverIndex = update.destination.index;
        const dragStop = state.drafts[dragIndex];

        const resortedDrafts = reorderedDrafts.resortValue(dragIndex, hoverIndex, dragStop);

        dispatch({type: 'reorder', value: resortedDrafts});
    };

    const hasProductionPlan = drafts.map(orderToProductionPlans).some(Boolean);
    const hasSalmonella = drafts.map(orderToSalmonellaResult).some(Boolean);

    return {
        isBackwards: state.isBackwards,
        modifiedDrafts: state.drafts,
        stopBasis: state.basis,
        startAtDate: state.date,
        interval: state.interval,
        offsets: state.offsets,
        changeBasis: (basis) => dispatch({type: 'basis', value: basis}),
        changeDate: (date) => dispatch({type: 'date', value: date}),
        changeInterval: (interval) => dispatch({type: 'interval', value: interval}),
        toggleDirection: () => dispatch({type: 'direction'}),
        reorderFunction: dropFunction,
        changeOffset: (index, value) => dispatch({type: 'offsets', index, value}),
        hasMultiStop,
        hasProductionPlan,
        hasSalmonella
    };
};
