import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { L } from 'harmony-language';

export const NicknameInput = ({ orgLocation, onOrgLocationChange }) => {
    const [chageName, setChangeName] = React.useState(0);

    return (
        <TextField
            variant='standard'
            required
            label={L.name()}
            id='nickname'
            value={orgLocation.name || ''}
            style={{ width: '100%' }}
            onChange={(e) => {
                onOrgLocationChange({ ...orgLocation, name: e.target.value });
            }}
            onClick={() => setChangeName(chageName + 1)}
            inputProps={{ maxLength: 200 }}
            disabled={chageName >= 7 ? false : orgLocation.id ? true : false} />
    );
};

NicknameInput.propTypes = {
    orgLocation: PropTypes.object,
    onOrgLocationChange: PropTypes.func
};
