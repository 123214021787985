import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { OrgQueryKeys } from '../../api/config';
import { L } from 'harmony-language';
import { Button, TextField } from '@mui/material';
import { AgisticsDateTimePicker } from '../shared/inputs/agistics-date-time-picker';
import { useAddOrganizationResourceDowntimeMutation } from '../../api/mutations/add/use-add-organization-resource-downtime-mutation';
import { useEditOrganizationResourceDowntimeMutation } from '../../api/mutations/edit/use-edit-organization-resource-downtime-mutation';
import { DaysOfWeek } from './downtime-days-of-week';
import { CircularLoading } from '../shared/circular-loading';
import { calculateDayOfWeekOffset } from './util/downtime-utils';

export const DowntimeEntry = (props) => {
    const { companyId, resourceType, resourceId, existingDowntime, closeModal } = props;

    const [downtime, setDowntime] = React.useState({});

    useEffect(() => {
        if (existingDowntime) {
            setDowntime(existingDowntime);
        }
    }, []);

    const { mutate: addDowntime, isLoading: isCreating } = useAddOrganizationResourceDowntimeMutation(companyId, OrgQueryKeys[resourceType], L[resourceType](), resourceId);
    const { mutate: editDowntime, isLoading: isUpdating } = useEditOrganizationResourceDowntimeMutation(companyId, OrgQueryKeys[resourceType], L[resourceType](), resourceId);
    const isLoading = isCreating || isUpdating;

    const downtimeChanged = (prop, value) => {
        const downtimeChanged = { ...downtime };

        downtimeChanged[prop] = value;
        setDowntime(downtimeChanged);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        downtime.description = downtime.description || '';
        if (downtime.id) {
            editDowntime(downtime);
        } else {
            addDowntime(downtime);
        }
        closeModal();
    };

    return (
        <div data-testid='resource-downtime-entry'>
            <form id='create-downtime-entry' name='create-downtime-entry'
                onSubmit={(event) => handleSubmit(event, false)}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }
                }>
                    {isLoading && <CircularLoading/>}
                    <AgisticsDateTimePicker
                        disabled={isLoading}
                        required
                        label={L.begin()}
                        onChange={(date) => {
                            downtimeChanged('begin', date);
                        }}
                        value={downtime.begin || null}
                    />
                    <AgisticsDateTimePicker
                        disabled={isLoading}
                        required
                        label={L.end()}
                        onChange={(date) => {
                            downtimeChanged('end', date);
                        }}
                        value={downtime.end || null}
                    />
                    <div style={{
                        margin: '1rem 0 0.5rem 0',
                    }}>
                        <DaysOfWeek clickable={!isLoading}
                            daysOfWeekOffset={calculateDayOfWeekOffset(downtime?.begin)}
                            daysOfWeekBitmask={downtime?.daysOfWeek || '0'}
                            onChange={(newBitmask) => downtimeChanged('daysOfWeek', newBitmask)}/>
                    </div>
                    <div style={{
                        margin: '0.5rem 0 0.8rem 0',
                        width: '300px'
                    }}>
                        <TextField
                            variant='standard'
                            disabled={isLoading}
                            label={L.description()}
                            autoFocus={true}
                            maxRows='5'
                            minRows='1'
                            fullWidth={true}
                            onChange={(e) => {
                                downtimeChanged('description', e.target.value);
                            }}
                            value={downtime.description || ''} />
                    </div>
                </div>
                <Button className='submit-button' type='submit' variant='contained' disabled={isLoading}
                    color='primary'>{L.save()}</Button>
            </form>
        </div>
    );
};

DowntimeEntry.propTypes = {
    companyId: PropTypes.number.isRequired,
    resourceType: PropTypes.string.isRequired,
    resourceId: PropTypes.number,
    existingDowntime: PropTypes.object,
    closeModal: PropTypes.func
};
