import React from 'react';

// For use with react react-beautiful-dnd drag handle component
export function verticalLockDragStyle(style) {
    if (style.transform) {
        const axisLockY =
            'translate(0px' +
            style.transform.slice(
                style.transform.indexOf(','),
                style.transform.length
            );

        return {
            ...style,
            transform: axisLockY
        };
    }
    return style;
}

// https://github.com/atlassian/react-beautiful-dnd/issues/374#issuecomment-569817782
export function disableReorderingOnDrag(style, snapshot) {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
        return style;
    }

    return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: `0.001s`
    };
}


export function usePresentationMenuId(menuId) {
    return React.useMemo(() => ({
        id: menuId,
    }), [menuId]);
}
