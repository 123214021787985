import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { DownTime } from '../../../types';

type ResourceTypeType = 'tractors' | 'trailers' | 'users' | 'loadingTeams' | 'locations';

async function deleteDowntime(companyId: number, resourceType: ResourceTypeType, resourceId: number, options: any) {
    const downtimeId = options.downtimeId;
    const path = OrgQueryKeys.resolveDowntime(companyId, OrgQueryKeys[resourceType], resourceId, downtimeId);

    await axios.delete(`${API_BASE}/api${path}`);
}

export const useDeleteOrganizationResourceDowntimeMutation = (companyId: number, resourceType: ResourceTypeType, resourceId: number) => {
    const key = OrgQueryKeys.resolveDowntime(companyId, OrgQueryKeys[resourceType], resourceId);
    const queryClient = useQueryClient();

    return useMutation((...args) => deleteDowntime(companyId, resourceType, resourceId, ...args), {
        ...mutationOptions([key], queryClient),
        onMutate: (options) => {
            const downtimeId = options.downtimeId;

            queryClient.cancelQueries([key]);

            const previousDowntime = queryClient.getQueryData<DownTime[]>([key]);
            if (previousDowntime) {
                const newDowntime = previousDowntime.filter(x => x.id !== downtimeId);
                // Optimistically update to the new value
                queryClient.setQueryData([key], newDowntime);
            }

            // Return a rollback function
            return () => queryClient.setQueryData([key], previousDowntime);
        }
    });
};
