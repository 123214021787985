import { getCurrentLanguage, getCurrentUnitSystem } from 'harmony-language';
import { getTimezone } from '../../utils/date-time-utils';
import { OnTimeReportMode } from './on-time/on-time-report';

export const getUserSettings = () => {
    const language = getCurrentLanguage();
    const units = getCurrentUnitSystem();
    const timezone = getTimezone();

    return {
        language,
        units,
        timezone,
    }
}

export const getDriverEfficiencyUrlAndParams = (
    useReplica: string,
    organizationId: number,
    dateBegin: string,
    dateEnd: string,
    driverIds: number[],
) => {
    const reportUrl = new URL(`${API_BASE}/api/reports/driver-efficiency`);
    const reportParams = new URLSearchParams(reportUrl.search);
    reportParams.set('useReplica', useReplica);
    reportParams.set('organizationId', String(organizationId));
    reportParams.set('dateBegin', dateBegin);
    reportParams.set('dateEnd', dateEnd);
    driverIds.forEach(driverId => {
        reportParams.append('driverIds[]', driverId.toString());
    });

    return {
        reportUrl,
        reportParams,
    }
}

export const getDecisionUrlAndParams = (
    useReplica: string,
    organizationId: number,
    dateBegin: string,
    dateEnd: string,
    siteModes: string[],
    siteTypeIds: number[],
    siteIds: number[],
) => {
    const reportUrl = new URL(`${API_BASE}/api/reports/decision`);
    const reportParams = new URLSearchParams(reportUrl.search);
    reportParams.set('useReplica', useReplica);
    reportParams.set('organizationId', String(organizationId));
    reportParams.set('dateBegin', dateBegin);
    reportParams.set('dateEnd', dateEnd);
    siteModes.forEach(siteMode => {
        reportParams.append('siteModes[]', siteMode);
    });
    siteTypeIds.forEach(siteTypeId => {
        reportParams.append('siteTypeIds[]', siteTypeId.toString());
    });
    siteIds.forEach(siteId => {
        reportParams.append('siteIds[]', siteId.toString());
    });

    return {
        reportUrl,
        reportParams,
    }
}

export const getSequencingUrlAndParams = (
    useReplica: string,
    organizationId: number,
    dateBegin: string,
    dateEnd: string,
    trailerIds: number[],
    originSiteIds: number[],
    destinationSiteIds: number[],
) => {
    const reportUrl = new URL(`${API_BASE}/api/reports/sequencing-feed`);
    const reportParams = new URLSearchParams(reportUrl.search);
    reportParams.set('useReplica', useReplica);
    reportParams.set('organizationId', String(organizationId));
    reportParams.set('dateBegin', dateBegin);
    reportParams.set('dateEnd', dateEnd);
    trailerIds.forEach(trailerId => {
        reportParams.append('trailerIds[]', trailerId.toString());
    });
    originSiteIds.forEach(originSiteId => {
        reportParams.append('originSiteIds[]', originSiteId.toString());
    });
    destinationSiteIds.forEach(destinationSiteId => {
        reportParams.append('destinationSiteIds[]', destinationSiteId.toString());
    });

    return {
        reportUrl,
        reportParams,
    }
}

export const getSplitStopUrlAndParams = (
    useReplica: string,
    organizationId: number,
    dateBegin: string,
    dateEnd: string,
    locationIds: number[],
) => {
    const reportUrl = new URL(`${API_BASE}/api/reports/split-stop`);
    const reportParams = new URLSearchParams(reportUrl.search);

    reportParams.set('useReplica', useReplica);
    reportParams.set('organizationId', String(organizationId));
    reportParams.set('dateBegin', dateBegin);
    reportParams.set('dateEnd', dateEnd);
    locationIds.forEach(locationId => {
        reportParams.append('locationIds[]', locationId.toString());
    });

    return {
        reportUrl,
        reportParams,
    }
};

export const getOnTimeUrlAndParams = (
    useReplica: string,
    organizationId: number,
    dateBegin: string,
    dateEnd: string,
    mode: OnTimeReportMode,
    stopTypes: string[],
    customerIds: number[],
    cargoTypeIds: number[],
) => {
    const reportUrl = new URL(`${API_BASE}/api/reports/on-time`);
    const reportParams = new URLSearchParams(reportUrl.search);

    reportParams.set('useReplica', useReplica);
    reportParams.set('organizationId', String(organizationId));
    reportParams.set('dateBegin', dateBegin);
    reportParams.set('dateEnd', dateEnd);
    reportParams.set('mode', mode);
    stopTypes.forEach(stopType => {
        reportParams.append('stopTypes[]', stopType);
    });
    customerIds.forEach(customerId => {
        reportParams.append('customerIds[]', customerId.toString());
    });
    cargoTypeIds.forEach(cargoTypeId => {
        reportParams.append('cargoTypeIds[]', cargoTypeId.toString());
    });

    return {
        reportUrl,
        reportParams,
    }
};
