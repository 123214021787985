import React from 'react';
import { ReportFilters } from '../report-filters';
import { ReportBase } from '../report-base';
import { localNow } from '../../../utils/date-time-utils';
import { getOnTimeUrlAndParams } from '../report-utils';
import { OnTimeReportColumns } from '../report-types';
import { ColumnSort } from '@tanstack/react-table';
import { useReports } from '../use-reports';
import { useOnTimeColumns } from './on-time-columns';
import { OnTimeFilters } from './on-time-filters';

export enum OnTimeReportMode {
    'day' = 'day',
    'week' = 'week',
    'month' = 'month',
}

const defaultSort: ColumnSort = {
    id: 'customer_name',
    desc: false,
}

interface OnTimeReportProps {
    isAdmin: boolean;
    companyId: number;
}

export const OnTimeReport: React.FC<OnTimeReportProps> = (props) => {
    const { isAdmin, companyId } = props;
    const [organizationId, setOrganizationId] = React.useState<number>(isAdmin ? 0 : companyId);
    const [startDate, setStartDate] = React.useState<string>(localNow().weekday(0).startOf('day').toISOString());
    const [endDate, setEndDate] = React.useState<string>(localNow().weekday(6).endOf('day').toISOString());
    const [mode, setMode] = React.useState<OnTimeReportMode>(OnTimeReportMode.week);
    const [stopTypes, setStopTypes] = React.useState<string[]>([]);
    const [customerIds, setCustomerIds] = React.useState<number[]>([]);
    const [cargoTypeIds, setCargoTypeIds] = React.useState<number[]>([]);
    const columns = useOnTimeColumns(isAdmin);
    const { reportUrl, reportParams } = getOnTimeUrlAndParams(isAdmin ? 'true' : 'false', organizationId, startDate, endDate, mode, stopTypes, customerIds, cargoTypeIds);
    const { data, runReport, table, isLoading } = useReports<OnTimeReportColumns>(defaultSort, columns, reportUrl, reportParams, false);

    return (
        <>
            <ReportFilters
                isAdmin={isAdmin}
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                runReport={runReport}
                mode={mode}
                setMode={setMode}
            >
                <OnTimeFilters
                    isAdmin={isAdmin}
                    stopTypes={stopTypes}
                    setStopTypes={setStopTypes}
                    customerIds={customerIds}
                    setCustomerIds={setCustomerIds}
                    cargoTypeIds={cargoTypeIds}
                    setCargoTypeIds={setCargoTypeIds}
                />
            </ReportFilters>
            <ReportBase
                table={table}
                hasData={Boolean(data)}
                isLoading={isLoading}
                runReport={runReport}
                confined={true}
            />
        </>
    );
};
